import React from "react"

import { Spinner } from "react-bootstrap"

import { PowerIcon } from "@heroicons/react/24/solid"

import { getUser, signOut } from "../../core/plugins/Entry"
export const UserHeader = ({ title ="Welcome Back", admin = "Agency Admin" }) => {
  const [logout_loading, setLogoutLoading] = React.useState(false)
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="admin-type">{admin}</p>
          <h2 className="text-left">{title}</h2>
          <h4 className="text-username text-left">
            {getUser() && getUser()?.attributes?.email}
          </h4>
        </div>
        <div
          className="icon-sign-out"
          onClick={() => {
            signOut()
            setLogoutLoading(true)
          }}
        >
          {logout_loading && <Spinner animation="border" size="sm" />}
          {!logout_loading && <PowerIcon />}
        </div>
      </div>
    </>
  )
}
