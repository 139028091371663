import Layout from "components/layouts/Layout";
import React from "react";



const AppCollection = () =>{

    return (
        <Layout>
           <article className="container py-4 static-content-page">
        <div className="row justify-content-center">
          <div className="col-md-6">
          <h1>
              <strong>
               Privacy Policy
              </strong>
            </h1>
<p>
inCommunity Inc ABN 65 653 625 605 collects your personal information to register you as a user of its Refer Connect Assist App. We collect, use and disclose your personal information in the manner set out in our Privacy Policy located at <a href="/privacy">here</a> and as permitted by law. <br/><br/>
The Refer Connect Assist App allows support services to refer people in need of support services to organisations providing support services. If you do not provide us with your personal information you will not be able to register for or access the Refer Connect Assist App or make use of our referral services. <br/><br/>
Our Privacy Policy explains how we use and collect, how you may access and correct, your personal information, and how you can lodge a complaint regarding the handling of your personal information and how we will handle any complaint. By providing your personal information to us, you consent to the collection, use and disclosure of your personal information as described in the Privacy Policy.
</p>
            </div>
            </div>
            </article>
        </Layout>
    )
}

export default AppCollection;