import axios from 'axios';
const apiKey = "wVkYQzUE7C49AczToTaZH1On8kfuaasg5pK48aML";
const httpClientAxios = axios.create({
  baseURL: "https://opebcmz3wh.execute-api.ap-southeast-2.amazonaws.com/PROD",
  headers: {
    "x-api-key": apiKey,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*"
  }
})
export {httpClientAxios };