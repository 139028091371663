import Layout from "components/layouts/Layout";
import React from "react";

export const TermsPage = () => {
  return (
    <Layout>
      <article className="container py-4 static-content-page">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h1>
              <strong>
              Connect Assist App Terms and Conditions
              </strong>
            </h1>
            <section>
              <br></br>
              <h2>Introduction</h2>
              1. These terms and conditions apply to your access to and use of
              the Refer Connect Assist App (Refer Connect Assist App). The Refer
              Connect Assist App is operated by inCommunity Inc ABN 65 653 625
              605 (referred to as “inCommunity”; “we”, “us” and “our”).
              <br />
              <br />
              2. By accessing and using the Refer Connect Assist App, you agree
              to these terms and conditions, which include our Privacy Policy
              (available at <br/> <a href="/privacy-policy">Privacy policy</a>). You
              should review our Privacy Policy and these terms and conditions
              carefully and immediately cease using the Refer Connect Assist App
              if you do not agree to these terms and conditions. <br />
              <br />
              3. You acknowledge that these terms and conditions form a legally
              binding agreement between you (either as an individual or an
              organisation on whose behalf you access to the Refer Connect
              Assist App) and inCommunity.
              <br />
              <br />
              <h2>Registration</h2>
              4. You must have a registered account to access and use the Refer
              Connect Assist App. <br />
              <br /> 5. In order to be eligible for a registered account, you or
              your company must have signed up and be approved as a Community
              Organisation with inCommunity (<strong>
                Approved Community Organisation</strong>).
              Approved Community Organisations may set up their employees or
              agents to register their own accounts through the Refer Connect
              Assist App. <br />
              <br />
              6. You are responsible for the security of your account, including
              for any and all use and activity carried out by others on your
              behalf on your account. <br />
              <br />
              7. You must ensure that all usernames and passwords required to
              access the Refer Connect Assist App and your account are kept
              secure and confidential. You must immediately notify us of any
              unauthorised use of your account, passwords or any other breach of
              security and we will reset your password and you must take all
              other actions that we reasonably deem necessary to maintain or
              enhance the security of our computing systems and networks and
              your access to the Refer Connect Assist App.
              <br />
              <br />
              <h2>Access and use</h2>
              8. You must use the Refer Connect Assist App for lawful purposes
              only and ensure that your access to, or use of, the Refer Connect
              Assist App is not illegal or prohibited by law. You agree at all
              times to deal with any information, materials or data provided or
              accessed through the Refer Connect Assist App in a manner which
              complies with all applicable laws. <br />
              <br />
              9. In accessing and using the Refer Connect Assist App, you agree:{" "}
              <br />
              <br />
              <ul>
                <li>
                  9.1 that you will only use the Refer Connect Assist App
                  strictly in accordance with these terms and conditions;
                </li>
                <li>
                  9.2 provide true and accurate information to inCommunity in
                  respect of any information or request submitted through the
                  Refer Connect Assist App;
                </li>
                <li>
                  9.3 not to provide false or misleading information or engage
                  in misleading or deceptive conduct in connection with the
                  Refer Connect Assist App;
                </li>

                <li>
                  9.4 not to access or attempt to access materials or functions
                  on the Refer Connect Assist App you are not authorised to
                  access or use;{" "}
                </li>

                <li>
                  9.5 not to interfere with or disrupt (or attempt to interfere
                  or disrupt) the rights of others or their use of the Refer
                  Connect Assist App, including to undermine the security or
                  integrity of our computing systems or networks;{" "}
                </li>

                <li>
                  9.6 not transmit, or submit through the Refer Connect Assist
                  App, any files that may damage our or any other person's
                  computing devices or software, content that may be offensive,
                  or material or data in violation of any law (including data or
                  other material protected by copyright or trade secrets which
                  you do not have the right to use); and{" "}
                </li>

                <li>
                  9.7 not attempt to modify, copy, adapt, reproduce,
                  disassemble, decompile or reverse engineer any computer
                  programs used to deliver or to operate the Refer Connect
                  Assist App except as is strictly necessary to use the Refer
                  Connect Assist App for its normal operation.
                </li>
              </ul>
              <br />
              <br />
              10. We may suspend or remove access to some or all of the
              functionality of the Refer Connect Assist App, conditionally or
              unconditionally, in our absolute discretion. Some parts of the
              Refer Connecr Assist App may only be available to certain classes
              of Approved Community Organisations and users.
              <br />
              <br />
              <h2>Assistance services</h2>
              11. The Refer Conenct Assist App is provided to assist Approved
              Community Organisations and their employees or agents to raise or
              report individual rental support requests through the Refer
              Connect Assist App in order for inCommunity to process and refer
              people in need of support services to third party community
              support organisations and providers (<strong>Request</strong>). <br /> <br /> 
              12.
              While we endeavour to process each Request, you acknowledge and
              agree that we do not warrant, and are under no obligation to,
              either review, process or refer a Request. As part of any Request
              we refer, you acknowledge and agree that we may also pass on your
              contact information and details to the third party community
              support organisation or provider. <br /> <br /> 
              13. You must
              ensure that you provide as much information as possible relevant
              to each Request to allow us to review, process and refer a
              Request, and that all information is accurate, complete and not
              misleading. <br/><br/>
              14. You must use all reasonable endeavours to advise
              the relevant individual and seek their authorisation and consent,
              to the submitting of their details and referral to a third party
              community support organisation or provider, prior to submitting a
              Request through the Refer Connect Assist App. Where you have not
              sought or obtained the authorisation or consent of the relevant
              individual, you acknowledge and agree that we may not be able to
              process a Request or otherwise appropriately or fully assist that
              individual.
              <br /> <br />
              <h2>Collection and use of information</h2>
              15. As part of your access to and use of the Refer Connect Assist
              App, we may collect information and data from or about you,
              including details of individuals the subject of a Request.
              <br /> <br />
              16. Before submitting any information or data to us through the
              Refer Connect Assist App, you must ensure that you are authorised
              to provide us with that information and data. You acknowledge that
              you remain ultimately responsible for the information and data
              which has been submitted and warrant that such information and
              data has been submitted in accordance with all applicable laws.
              <br /> <br /> 17. Our collection, use and disclosure of any
              personal information will be in accordance with our Privacy Policy
              as amended from time to time.
              <h2>Intellectual property rights</h2>
              18. Unless otherwise indicated, we own or license from third
              parties all rights, title and interest to intellectual property
              rights (including copyright and trade marks) in the Refer Connect
              Assist App and in all of the material (including all text,
              graphics, logos, audio and software) made available in the refer
              Connect Assist App (Content).
               <br /> <br /> 19. Your use of the
              Refer Connect Assist App, and use of and access to any Content,
              does not grant or transfer any rights, title or interest to you in
              relation to the Refer Connect Assist App or the Content. Except to
              the extent permitted by law or expressly permitted under these
              terms and conditions, any reproduction or redistribution of the
              Refer Connect Assist App or the Content is strictly prohibited.
              Links on the Refer Connect Assist App
              <br /> <br />
              20. The information contained in the Refer Connect Assist App is
              of a general nature and for your information only, not
              comprehensive and is intended only to provide a summary of the
              subject matter covered. You should undertake your own
              investigations in relation to the suitability of the information
              and the Refer Connect Assist App. <br /> <br />
              21. While we use all reasonable efforts to ensure the accuracy and
              completeness of the information in the Refer Connect Assist App,
              to the extent permitted by law, we make no representation or
              warranty whatsoever in relation to the information in the Refer
              Connect Assist App. You should monitor any changes to the
              information contained in the Refer Connect Assist App and make
              your own assessment of that information. <br /> <br />
              22. The Refer Connect Assist App may display, include or make
              available content, links to websites operated by third parties or
              materials of third parties (Third Party Materials). Third Party
              Materials are provided for your convenience only and may not
              remain current or be maintained. Unless expressly stated
              otherwise, we do not warrant or endorse and do not assume any
              liability or responsibility to you or any other person in relation
              to any Third Party Materials.
              <br /> <br />
              <h2>Security</h2>
              23. We are not liable to you or anyone else if interference with
              or damage to your computer or mobile systems occurs in connection
              with the use of the Refer Connect Assist App. You must take your
              own precautions to ensure that your use of the Refer Connect
              Assist App is free of viruses or other malicious code (such as
              worms or Trojan horses) that may interfere with or damage the
              operations of your computer or mobile systems. <br /> <br />
              24. You must maintain your own separate copies of all information
              and data submitted to the Refer Connect Assist App. We do not make
              any guarantees that there will be no loss of information or data
              and we expressly exclude liability for any loss of information or
              data however caused.
              <br /> <br />
              <h2>Disclaimer</h2>
              25. To the maximum extent permitted by law, we make no warranties
              or representations about the Refer Connect Assist App, including
              without limitation, any guarantee, condition or warranty of
              merchantability, acceptable quality, fitness for purpose or
              fitness for disclosed result or any actions that may follow a
              Request being submitted. You are responsible for following up any
              individual to whom a Request relates as to whether a resolution
              has been achieved or if further support is required. 
              <br /> <br />
             
              26. To the maximum extent permitted by law, in no event shall we
              be liable for any direct or indirect loss, damage or expense, no
              matter how caused, which may be suffered due to your use of the
              Refer Connect Assist App or any action, inaction or result
              following a Request, any information or materials provided through
              or contained on our website or the Refer Connect Assist App, or as
              a result of the inaccessibility of the Refer Connect Assist App
              and/or the fact that certain information or materials contained on
              it are incorrect, incomplete or not up-to-date.
              <br/>   <br/>
              <h2>
                Availability of and changes to the Refer Connect Assist App
              </h2>
              27. While we undertake reasonable endeavours to ensure the Refer
              Connect Assist App is always available, it is possible that on
              occasions the refer Conenct Assist App may be unavailable to
              permit maintenance or other development activity to take place.
              <br /> <br />
              28. If the Refer Connect Assist App will be interrupted for
              extended periods of time and will affect the services we provide
              to you, we will use reasonable endeavours to notify you of such
              interruption. However, we do not represent, warrant or guarantee
              that the Refer Connect Assist App will be available at all times.
              <br /> <br />
              29. We reserve the right to make any changes to the Refer Connect
              Assist App or services offered through Refer Connect Assist App as
              necessary or desirable. While we will use all reasonable
              endeavours to give prior notice of such changes, you acknowledge
              that prior notice may not be able to be given in respect of all
              changes.   <br/>   <br/>
              <h2>Termination</h2>
              30. We may terminate these terms and conditions, including your
              registered account and your access the Refer Connect Assist App,
              if you breach any provision of these terms and conditions or
              otherwise in our discretion, without the need to provide reasons.
              <br /> <br />
              31. We may also in our absolute discretion, revoke the approval of
              any Approved Community Organisation where we consider there is a
              reasonable concern that the continued use of the Refer Connect
              Assist App poses an unacceptable risk to individuals or the
              community generally.   <br/>   <br/>
              <h2>General</h2>
              32. You may not assign or transfer any rights to any other person
              without our prior written consent. <br /> <br />
              33. If any part or provision of these terms and conditions is
              invalid, unenforceable or in conflict with the law, that part or
              provision is replaced with a provision which, as far as possible,
              accomplishes the original purpose of that part or provision. The
              remainder of these terms and conditions will be binding on the
              parties.
              <br /> <br />
              34. These terms and conditions are governed by the laws in force
              in the State of Queensland, Australia and you agree to submit to
              the exclusive jurisdiction of the courts of that State in respect
              of any dispute arising from these terms and conditions. These
              terms and conditions were lasted updated on 17 January 2024.
            </section>
          </div>
        </div>
      </article>
    </Layout>
  );
};
