import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';

import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import { Disclosure } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  ArrowRightIcon,
  CalendarDaysIcon,
  ChevronUpIcon,
  FlagIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import DefaultLayout from 'components/layouts/DefaultLayout';
import {
  apiGenerateReport,
  apiGetAllInquiresIA,
  apiGetAllIssues,
  apiGetAllPMA,
  apiGetAllPMs,
  apiGetSupportServices,
} from 'core/api';
import { UserHeader } from '../../shared';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
function IADashboard() {
  const navigate = useNavigate();

  const routeTo = url => {
    navigate(url);
  };

  const [pms, setPms] = React.useState(false);
  const [pmas, setPmas] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [inquiries, setInquiry] = React.useState(false);
  const [allInquiries, setAllInquiries] = React.useState(false);
  const [issues, setIssues] = React.useState(false);
  const [supportService, setSupportSerivce] = React.useState(false);

  const getDropodwnData = async () => {
    setLoading(true);

    await apiGetAllInquiresIA().then(response => {
      if (response) {
        setLoading(false);
        setInquiry(response);
        setAllInquiries(response);
      }
    });

    await apiGetSupportServices().then(response => {
      setSupportSerivce(response);
    });

    await apiGetAllPMs().then(response => {
      setPms(response);
    });

    await apiGetAllPMA().then(response => {
      setPmas(response);
    });

    await apiGetAllIssues().then(response => {
      setIssues(response);
    });
  };
  React.useEffect(() => {
    getDropodwnData();
  }, []);

  const clearAll = () => {
    setLoading(true);
    apiGetAllInquiresIA().then(response => {
      if (response) {
        setLoading(false);
        setInquiry(response);
        setAllInquiries(response);
        setFilterModal(false);
      }
    });
  };

  const [filterModal, setFilterModal] = React.useState(false);

  const openFilter = () => {
    setFilterModal(true);
  };

  const [progress, setProgress] = React.useState(false);
  const generateReport = () => {
    setProgress(true);
    apiGenerateReport().then(response => {
      setProgress(false);
      window.open(response);
    });
  };

  const [sIndex, setSIndex] = React.useState(false);
  const [allfilters, setAllFilters] = React.useState({
    start_date: new Date(),
    end_date: new Date(),
    PMID: null,
    PMAID: null,
    SSMSWorkerID: null,
  });

  const setFiltersPMA = e => {
    setAllFilters(prevState => ({
      ...prevState,
      PMAID: e.target.value,
    }));
  };
  const setFiltersPM = e => {
    setAllFilters(prevState => ({
      ...prevState,
      PMID: e.target.value,
    }));
  };

  const setFiltersSSM = e => {
    setAllFilters(prevState => ({
      ...prevState,
      SSMSWorkerID: e.target.value,
    }));
  };

  const setEndDate = e => {
    setAllFilters(prevState => ({
      ...prevState,
      end_date: e,
    }));
  };

  const setStartDate = e => {
    setAllFilters(prevState => ({
      ...prevState,
      start_date: e,
    }));
  };

  const filterTickets = () => {
    let filtered =
      allInquiries &&
      allInquiries?.data?.length &&
      allInquiries?.data.filter(
        q =>
          // eslint-disable-next-line no-mixed-operators
          (moment(q.CreateDateTime).isSameOrBefore(allfilters.end_date) &&
            // eslint-disable-next-line no-mixed-operators
            moment(q.CreateDateTime).isSameOrAfter(allfilters.start_date)) ||
          (q.WID === allfilters.PMID &&
            // eslint-disable-next-line no-mixed-operators
            q.SSMOID === allfilters.PMAID &&
            q.SWName === allfilters.SSMSWorkerID), // check the support service name
      );
    setInquiry({
      data: filtered,
      size: filtered?.length,
    });
  };

  const [pages, setPages] = React.useState(false);
  // React.useEffect(() => {
  //   if (inquiries) {
  //     setPages(new Array(Math.ceil(inquiries.size / 10)).fill(0));
  //   }
  // }, [inquiries]);

  const viewAcceptedServices = async supportRequest => {
    // eslint-disable-next-line no-template-curly-in-string
    navigate(`/IA/raise-issues?CID=${supportRequest.ClientID}`);
  };
  return (
    <>
      <DefaultLayout>
        <Modal
          className='filter-modal'
          show={filterModal}
          centered
          size='xl'
          backdrop='static'
          onHide={() => {
            setFilterModal(false);
          }}
        >
          <Modal.Header closeButton>
            <h2>Filter by</h2>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-6'>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  Date
                </div>
                <div className='d-flex filter-date-wapper justify-content-between'>
                  <div className='datepicker-wrapper'>
                    <div className='picker-icon'>
                      <CalendarDaysIcon />
                    </div>

                    <div className='date-wrapper'>
                      <small>From date</small>
                      <DatePicker
                        selected={allfilters.start_date}
                        onChange={setStartDate}
                      />
                    </div>
                  </div>

                  <div className='datepicker-wrapper'>
                    <div className='picker-icon'>
                      <CalendarDaysIcon />
                    </div>
                    <div className='date-wrapper'>
                      <small>To date</small>
                      <DatePicker
                        selected={allfilters.end_date}
                        onChange={setEndDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='spacer-md'></div>
            <div>
              <form>
                <div className='row'>
                  <div className='col-md-4 col-sm-12'>
                    <div className='input-element'>
                      <div className='input-normal'>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          Organisations
                        </div>

                        <select onChange={setFiltersPMA}>
                          <option>Select Organisation</option>
                          {pmas &&
                            pmas.map(PMA => (
                              <option key={PMA.SSMOID} value={PMA.SSMOID}>
                                {PMA.CompanyName} 
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-12'>
                    <div className='input-element'>
                      <div className='input-normal'>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          Workers
                        </div>

                        <select onChange={setFiltersPM}>
                          <option>Select Workers</option>
                          {pms &&
                            pms.map(pm => (
                              <option key={pm.WID} value={pm.WID}>
                                {pm.Name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='col-md-4 col-sm-12'>
                    <div className='input-element'>
                      <div className='input-normal'>
                        <div
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          Assigned Support
                        </div>

                        <select onChange={setFiltersSSM}>
                          <option>Select Support</option>
                          {supportService &&
                            supportService.map(ss => (
                              <option key={ss['SSM-SWorkerID']} value={ss.Name}>
                                {ss.Name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className='row justify-content-center'>
                <div className='col-sm-3'>
                  <div className='input-element text-center'>
                    <button
                      className='primary-button no-animation'
                      onClick={() => {
                        setFilterModal(false);
                        filterTickets();
                      }}
                    >
                      <div className='button-text'>Filter</div>
                    </button>
                    <div className='spacer-sm'></div>

                    <div
                      onClick={clearAll}
                      style={{
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      Reset Filters{' '}
                      {loading ? <Spinner animation='border' size='sm' /> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className='container inner-page '>
          <div className='spacer-lg'></div>
          <div className='d-flex flex-column'>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-7 col-sm-12'>
                <UserHeader admin='inCommunity Admin' />
                <div className='spacer-lg'></div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div
                      className='user-button user-button-small user-button-small-special'
                      onClick={() => routeTo('/IA/agency-admin-list')}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='icon-left fill-gradient-orange'>
                          <img src='/images/org-admin-icon-box.png' alt='' />
                        </div>
                        <div className='user-button-text'>
                          Organisation Admins
                        </div>
                      </div>
                      <div className='icon-right'>
                        <ArrowRightIcon />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div
                      className='user-button user-button-small user-button-small-special'
                      onClick={() => routeTo('/IA/support-service')}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='icon-left fill-gradient-orange'>
                          <img src='/images/support-icon-box.png' alt='' />
                        </div>
                        <div className='user-button-text'>Support Services</div>
                      </div>
                      <div className='icon-right'>
                        <ArrowRightIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-7 col-sm-12'>
                <div className='spacer-sm'></div>
                <div className='accordian-list'>
                  <div className='d-flex sub-title-with-icon align-items-center justify-content-between'>
                    <div className='d-flex'>
                      <div className='icon-left'>
                        <FlagIcon />
                      </div>
                      <h3>
                        Enquires{' '}
                        {loading ? (
                          <Spinner animation='border' size='sm' />
                        ) : (
                          ''
                        )}
                        <small>
                          {inquiries &&
                          inquiries?.data &&
                          inquiries?.data?.length > 0 &&
                          !loading
                            ? inquiries?.data?.length + ' tickets found'
                            : '0 tickets found'}
                        </small>
                      </h3>
                    </div>
                    <div>
                      <div className='input-element text-center d-flex'>
                        {(allfilters.PMAID ||
                          allfilters.PMID ||
                          allfilters.SSMSWorkerID) && (
                          <button
                            className='primary-button primary-button-xsmall no-animation mr-2'
                            style={{ marginRight: 12 }}
                            onClick={clearAll}
                          >
                            <div className='right-icon'>
                              <ArrowPathIcon />
                            </div>
                            <div className='button-text'>Reset</div>
                          </button>
                        )}

                        <button
                          disabled={
                            !inquiries?.data && !inquiries?.data?.length
                          }
                          className='primary-button primary-button-xsmall no-animation'
                          onClick={openFilter}
                          style={{
                            marginRight: 12,
                          }}
                        >
                          <div className='right-icon'>
                            <FunnelIcon />
                          </div>
                          <div className='button-text'>Filter</div>
                        </button>

                        <div className='download-button'>
                          <button
                            onClick={generateReport}
                            disabled={
                              !inquiries?.data && !inquiries?.data?.length
                            }
                          >
                            {progress ? (
                              <Spinner size='sm' />
                            ) : (
                              <ArrowDownTrayIcon />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='spacer-sm'></div>

                  <div className=''>
                    <div>
                      {inquiries?.data &&
                        inquiries?.data?.length > 0 &&
                        inquiries?.data.map((inq, i) => (
                          <Disclosure
                            as='div'
                            key={i}
                            className={
                              sIndex === i ? 'acc-wrapper open' : 'acc-wrapper'
                            }
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className='d-flex align-items-center justify-content-between acc-wrapper-button'
                                  onClick={() => {
                                    setSIndex(i);
                                  }}
                                >
                                  <span className='text-orange flex-grow-1'>
                                    Ticket No TC &nbsp;{' '}
                                    {inq['SSM-InquiryID'].substr(0, 4)}
                                  </span>
                                  <ChevronUpIcon
                                    className={`h-5 w-5 ${
                                      open ? '' : 'transform rotate-180'
                                    }`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel
                                  className={
                                    open ? 'acc-panel open' : 'acc-panel'
                                  }
                                >
                                  <p>
                                    <span className='text-orange'>Date</span>
                                    <br />
                                    <span>
                                      {inq?.CreateDateTime
                                        ? moment(inq?.CreateDateTime).format(
                                            'YYYY-MMM-DD',
                                          )
                                        : '-'}
                                      &nbsp;
                                      {inq?.CreateDateTime
                                        ? moment(inq?.CreateDateTime).format(
                                            'hh:mm A',
                                          )
                                        : '-'}
                                    </span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Requested support
                                    </span>
                                    <br />
                                    <span>{inq?.IssueTitle}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Support service name
                                    </span>
                                    <br />
                                    <span>
                                      {inq?.SWName ? inq?.SWName : '-'}
                                    </span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Assined worker
                                    </span>
                                    <br />
                                    <span>
                                      {inq?.WorkerName ? inq?.WorkerName : '-'}
                                    </span>
                                  </p>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}

                      {inquiries && inquiries.length === 0 && (
                        <div className='empty-list-data'>
                          <div className='spacer-sm'></div>
                          <h4>No issues found</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
}

export default IADashboard;
