import React, { useState } from 'react';

import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ArrowRightIcon,
  LockClosedIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import {
  EyeIcon,
  EyeSlashIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';

import AppFooter from '../../../components/shared/footer';
import { apiGetPMA } from '../../../core/api';
import { signIn, verifyUserCode } from '../../../core/plugins/Entry';
import AuthLayout from '../../layouts/AuthLayout';
import { ROUTES } from 'core/routes';
function OrganisationLanding() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [payload, setPayload] = React.useState({
    username: '',
    password: '',
    type: 'OA',
  });

  const onChange = e => {
    setPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [tempUser, setTempUser] = useState(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const handleVerificationCodeChange = e => {
    setVerificationCode(e.target.value);
  };

  const [isVerifing, setIsVerifing] = React.useState(false);
  const submitVerificationCode = async () => {
    setIsVerifing(true);
    setLoading(true);

    try {
      const verified = await verifyUserCode(tempUser, verificationCode);
      if (verified) {
        apiGetPMA(tempUser.UUID).then(response => {
          if (response && response.SSMOID) {
            toast.success(`Welcome`);
            navigate(ROUTES.OA_DASHBOARD);
            setIsVerifing(false);
            setLoading(false);
          } else {
            toast.warn('User is not a Organisation Administrator');
          }
        });
        setShowVerificationModal(false);
      } else {
        toast.error('Invalid verification code');
        setIsVerifing(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Error verifying code');
      setIsVerifing(false);
      setLoading(false);
    }
  };

  const login = e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      signIn(payload).then(response => {
        console.log(response);
        setTempUser(response.tempUser);
        if (response.message === 'User is not confirmed.') {
          openConfirmUserModal();
        }
        if (response.requireVerification && response.user.username) {
          setTempUser(response.tempUser);
          setShowVerificationModal(true);
          // setLoading(false)
        }
      }).finally(()=>{
        setLoading(false);

      });
    }, 1000);
  };
  const [confirm, setConformPayload] = React.useState({
    email: '',
    code: '',
  });

  const onConfirmPayloadChange = e => {
    setConformPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [showConfirmUser, setShowConfirmUser] = React.useState(false);

  const openConfirmUserModal = () => {
    setShowConfirmUser(true);
  };

  const handleConfirmUserModalClose = () => {
    setShowConfirmUser(false);
  };

  const submitConfirmUser = () => {
    handleConfirmUserModalClose();
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Modal
        show={showConfirmUser}
        onHide={handleConfirmUserModalClose}
        backdrop='static'
        className='confirm-modal'
      >
        <Modal.Body>
          <div className='spacer-lg'></div>
          <div className='top-icon'>
            <InformationCircleIcon />
          </div>
          <div className='spacer-sm'></div>
          <h4>Organisation User not confirmed.</h4>
          <p>
            Verify your email address <br />
            <small>
              Please click the verification link sent to your email address
            </small>
          </p>
          <div className='input-element'>
            <button
              onClick={submitConfirmUser}
              className='primary-button primary-button-small'
            >
              <div className='button-text'>Close</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
        backdrop='static'
        className='verification-modal'
      >
        <Modal.Header>
          <Modal.Title>Verification Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the verification code sent to your email.</p>
          <div className='verification-input'>
            <input
              type='text'
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              placeholder='Enter code here'
              className='form-control'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='input-element'>
            <button
              onClick={submitVerificationCode}
              className='btn btn-primary primary-button'
              disabled={isVerifing}
            >
              {isVerifing ? <Spinner animation='border' size='sm' /> : 'Verify'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className='container login-form-page '>
        <div className='d-flex flex-column justify-content-center'>
          <div className='row justify-content-center'>
            <div className='col-md-8 text-center'>
              <div className='spacer-lg'></div>
              <div className='spacer-lg'></div>
              <div className='main-icon'>
                <img src='/images/org-admin-icon1.png' alt='' />
              </div>
              <h2 className='text-center'>
                Login as an <br /> Organisation admin
              </h2>
              <h4>Please sign in to continue</h4>
              <div className='spacer-lg'></div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-4'>
              <form onSubmit={login}>
                <div className='input-element'>
                  {/* <label>Username</label> */}
                  <div className='input-wrapper'>
                    <div className='left-icon'>
                      <UserIcon />
                    </div>
                    <input
                      autoFocus
                      value={payload.username}
                      name='username'
                      type='email'
                      placeholder='Email'
                      required
                      onChange={onChange}
                    ></input>
                  </div>
                </div>

                <div className='input-element'>
                  {/* <label>Password</label> */}
                  <div className='input-wrapper'>
                    <div className='left-icon'>
                      <LockClosedIcon />
                    </div>
                    <input
                      value={payload.password}
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password'
                      required
                      onChange={onChange}
                    ></input>
                    <div className='right-icon' onClick={togglePassword}>
                      {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                    </div>
                  </div>
                </div>

                <div className='input-element text-center'>
                  <button
                    disabled={loading}
                    type='submit'
                    className='primary-button'
                  >
                    <div className='button-text'>
                      {loading ? (
                        <Spinner animation='border' size='sm' />
                      ) : (
                        'Login'
                      )}
                    </div>
                    <div className='right-icon'>
                      <ArrowRightIcon />
                    </div>
                  </button>
                </div>
                <div className='spacer-sm'></div>
              </form>
              <div className='spacer-lg'></div>
              <div className='d-block justify-content-center text-center forgot-password-link-wrapper'>
                <a href={ROUTES.FORGOT}>Forgot Password ?</a>
              </div>
              <div className='spacer-lg'></div>
              <p
        className='text-center'
        style={{
          fontSize: '12px',
          color: '#888',
        }}
      >
        Refer Connet Assist by inCommunity
      </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganisationLanding;
