/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Autocomplete from 'react-google-autocomplete';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  ArrowRightIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowLeftIcon,
  ArrowLongLeftIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';

import {
  apiRequestCapacity,
  apiCreateInquiry,
  apiGetAllIssues,
  apiGetPM,
  apiGetPMA,
  apiGetSupportServicesDistance,
  apiCreateClient,
  apiGetAllRequests,
  apiClientConsentHandler,
} from 'core/api';
import { googleMapAPIKEY, mapOptions } from 'core/plugins/config';
import { getUser } from 'core/plugins/Entry';
import { ROUTES } from 'core/routes';
import AuthLayout from 'components/layouts/AuthLayout';
import { UserHeader } from 'components/shared';
const PMRaiseIssue = () => {
  const [issueValue, setIssueValue] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [currentSS, setCurrentSS] = React.useState([]);
  const [sIndex, setIndex] = React.useState(false);
  const [show, setModal] = React.useState(false);
  const [payload, setPayload] = React.useState({
    ClientID: '',
    ClientMobile: '',
    ClientEmail: '',
    ClientLocation: '',
    ClientName: '',
    ClientConsent: '',
    Age: '', //client age
    Gender: '', // client gender
    IssueID: '',
    IssueTitle: '',
    SSMOID: '',
    OrganisationCompany: '',
    WID: '',
    WorkerName: '',
    WorkerMobile: '',
    WorkerEmail: '',
    SWorkerID: '1',
    SWEmail: '',
    SWMobile: '',
    SendSMS: true,
    AdditionalInfo: '',
  });
  const [progress, setProgress] = React.useState(false);
  const [user_location, setUserLocation] = React.useState(false);
  const [client, setClient] = React.useState(false);
  const [issues, setIssues] = React.useState(false);
  const [support_serivces, setSupportServices] = React.useState(false);
  const [currentPMA, setCurrentPMA] = React.useState(false);
  const [currentPM, setCurrentPM] = React.useState(false);
  const [acceptedList, setAcceptedList] = React.useState(false);
  const location = useLocation();
  const [valueOk, setOk] = React.useState('ok');
  const prevStep = step => {
    setStep(step);
  };
  const switchOk = v => {
    setOk(v);

    if (v === 'ok') {
      setPayload(prevState => ({
        ...prevState,
        SendSMS: true,
      }));
    } else {
      setPayload(prevState => ({
        ...prevState,
        SendSMS: false,
      }));
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  const navigation = useNavigate();
  const routeTo = url => {
    navigation(url);
  };

  const onChange = e => {
    if (e.target.name === 'Gender') {
      setPayload(prevState => ({
        ...prevState,
        [e.target.name]: e.target.id,
      }));
      return;
    }
    setPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const goToNext = () => {
    if (!user_location) {
      toast.error('Please select your location');
      return;
    }
    if (payload.IssueTitle === '') {
      toast.error('Please select a support type');
      return;
    }

    if (payload.Age === '') {
      toast.error('Client Age is required');
      return;
    }

    if (payload.Gender === '') {
      toast.error('Client Gender is required');
      return;
    }

    if (payload.ClientName === '') {
      toast.error('Client Name is required');
      return;
    }

    if (payload.ClientName === '') {
      toast.error('Client Name is required');
      return;
    }

    if (payload.IssueTitle && user_location && payload.Age && payload.Gender) {
      toast.success('Please wait');
      setStep(1);

      const clientPayload = {
        Title: '',
        Email: payload.ClientEmail,
        Mobile: payload.ClientMobile,
        Name: payload.ClientName,
        Gender: payload.Gender,
        Age: payload.Age,
        Consent: false,
        Location: user_location,
        WID: getUser().UUID,
      };
      apiCreateClient(clientPayload).then(response => {
        console.log(response?.data?.message);
        setClient(response?.data?.message);
      });
      getProximitySupportWorkers();
    }
  };
  const selectValue = e => {
    if (e.target.value !== '') {
      setIssueValue(JSON.parse(e.target.value));
      setPayload(prevState => ({
        ...prevState,
        IssueTitle: e.target.value,
      }));
    } else {
      toast.error('Please select an support type');
    }
  };

  const updatePayload = (ss, i) => {
    if (
      currentSS.filter(c => c['SSM-SWorkerID'] === ss['SSM-SWorkerID']).length >
      0
    ) {
      setCurrentSS(
        currentSS.filter(c => c['SSM-SWorkerID'] !== ss['SSM-SWorkerID']),
      );
      return;
    }
    setCurrentSS([...currentSS, ss]);
    setIndex(i);
  };

  const onPlaceSelected = place => {
    if (place && place?.formatted_address) {
      setUserLocation(place.formatted_address);
    }
  };

  const mapServices = current_issues => {
    let c_issues = JSON.parse(current_issues);
    let current_services = [];
    if (issues && c_issues.length) {
      issues &&
        issues.forEach(s => {
          c_issues.forEach(i => {
            if (s.STID === i) {
              current_services.push(s);
            }
          });
        });

      return current_services;
    } else {
      return [];
    }
  };

  const createInquiry = () => {
    setProgress(true);
    apiCreateInquiry({
      Age: payload.Age,
      Gender: payload.Gender,
      ClientID: client,
      STID: issueValue.STID,
      IssueTitle: issueValue.Title,
      ClientName: payload.ClientName,
      SSMOID: currentPMA.SSMOID,
      OrganisationCompany: currentPMA.CompanyName
        ? currentPMA.CompanyName
        : 'N/A',
      WID: getUser().UUID,
      WorkerName: getUser().attributes.name,
      SSMSWorkers: currentSS.map(cs => ({
        SSMSWorkerID: cs['SSM-SWorkerID'],
        SWEmail: cs.Email,
        SWMobile: cs.Mobile,
        SWName: cs.Name,
      })),
      ClientMobile: payload.ClientMobile,
      ClientEmail: payload.ClientEmail,
      ClientLocation: user_location,
      WorkerMobile: currentPM.Mobile,
      WorkerEmail: getUser().attributes.email,
      SendSMS: payload.SendSMS,
      ContextualNotes: payload.AdditionalInfo,
    })
      .then(response => {
        if (response) {
          setProgress(false);
          toast.success('Support inquiry created successfully');
          if (step === 2) {
            setModal(true);
          }
          setCurrentSS([]);
          setUserLocation(false);
          setIssueValue(false);
          setClient(false);
          setRequest(true);
          setProgress(false);

          //request capacity
          requestCapacity(response);
        } else {
          toast.error('Error creating Support inquiry');
          setProgress(false);
        }
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const [loading_proximity, setLoadingProximity] = React.useState(false);
  const getProximitySupportWorkers = () => {
    setLoadingProximity(true);
    apiGetSupportServicesDistance({
      ClientLocation: user_location,
      STID: issueValue.STID,
      Age: payload.Age,
      Gender: payload.Gender,
    })
      .then(response => {
        if (response) {
          setLoadingProximity(false);
          setSupportServices(response.splice(0, 5));
        }
      })
      .finally(() => {
        setLoadingProximity(false);
      });
  };

  const requestCapacity = response => {
    if (client && currentSS.length > 0) {
      setProgress(true);
      apiRequestCapacity({
        InquiryID: response.inquiryID,
        WID: getUser().UUID,
        SWIDs: currentSS.map(c => ({
          ID: c['SSM-SWorkerID'],
          Name: c.Name,
          Email: c.Email,
          Mobile: c.Mobile,
        })),
        ClientID: client,
        ClientAge: payload.Age,
        ClientGender: payload.Gender,
        ClientName: payload.ClientName,
        ClientLocation: user_location,
        SupportType: issueValue.Title,
      })
        .then(response => {
          if (response.data.message) {
            toast.success('Capacity requested');
            setRequest(true);
            //  createInquiry();
          }
        })
        .catch(e => {
          toast.error('Error sending capacity request');
          setProgress(false);
        });
    } else {
      toast.error(
        'Client inoformation not available or Select one or more support service to send capacity request',
      );
    }
  };

  const [validator, setValidator] = React.useState({
    TenantMobile: false,
    TenantEmail: false,
    TenantName: false,
  });

  React.useEffect(() => {
    if (step === 0) {
      setCurrentSS([]);
      setUserLocation(false);
      setIssueValue(false);
    }
  }, [step]);

  const [requestModal, setRequest] = React.useState(false);
  const handleCloseRequest = () => {
    setRequest(false);
  };

  const getAllRequests = async () => {
    const clientID = location.search.split('CID=')[1];
    await apiGetAllRequests(clientID).then(response => {
      setAcceptedList(response?.message);
    });
  };

  React.useEffect(() => {
    if (location.search.includes('accepted-services')) {
      getAllRequests();
      setStep(1);
    } else {
      setAcceptedList(false);
      setStep(0);
    }
  }, [location]);
  React.useEffect(() => {
    apiGetAllIssues().then(response => {
      if (response) {
        setIssues(response);
      }
    });

    apiGetPM(getUser().UUID).then(response => {
      if (response) {
        setCurrentPM(response);
        apiGetPMA(response.SSMOID).then(response => {
          if (response) {
            setCurrentPMA(response);
          }
        });
      }
    });
  }, []);

  const [supportSerivce, setSupportService] = React.useState(false);
  const [ssIndex, setSSIndex] = React.useState(false);
  const selectSupportService = (item, index) => {
    setSSIndex(index);
    setSupportService(item);
  };

  const [selectedSupportRequest, setSelectedSupportRequest] =
    React.useState(false);
  const makeConsent = () => {
    if (supportSerivce) {
      setSelectedSupportRequest(
        JSON.parse(localStorage.getItem('supportRequest')),
      );
      setStep(2);
    } else {
      toast.error('Please select a support service');
      return;
    }
  };

  const sendConsentForInquiry = e => {
    e.preventDefault();
    if (!supportSerivce && !selectSupportService) {
      toast.error('Please select support service');
      return;
    }
    let consentPayload = {
      InquiryID: selectedSupportRequest['SSM-InquiryID'],
      IssueTitle: selectedSupportRequest.IssueTitle,
      ClientName: selectedSupportRequest.ClientName,
      SWName: supportSerivce.Name,
      SWEmail: supportSerivce.Email,
      SWMobile: supportSerivce.Mobile,
      SWID: supportSerivce.SWID,
      ClientMobile: selectedSupportRequest.ClientMobile,
      ClientEmail: selectedSupportRequest.ClientEmail,
      ClientConsent: payload.SendSMS,
      SendSMS: payload.SendSMS,
      WorkerName: currentPM.Name,
    };
    setProgress(true);
    apiClientConsentHandler(consentPayload)
      .then(response => {
        if (response?.data?.message) {
          setModal(true);
          localStorage.removeItem('supportRequest');
          setSupportService(false);
          setSelectedSupportRequest(false);
          setProgress(false);
          toast.success('Client consent updated successfully');
        }
      })
      .catch(error => {
        setProgress(false);
        toast.error('Client consent failed, please try again');
      });
  };

  return (
    <>
      <AuthLayout>
        <Modal
          show={requestModal}
          onHide={handleCloseRequest}
          backdrop='static'
          className='modal-small'
        >
          <Modal.Body>
            <div className='spacer-sm'></div>
            <div className='top-icon'>
              <CheckCircleIcon />
            </div>
            <div className='spacer-sm'></div>
            <h4>Capacity Requested</h4>
            <p>
              Successfully sent a capacity <br />
              request to the support service(s)
            </p>

            <div className='input-element-small text-center'>
              <button
                className='primary-button'
                onClick={() => {
                  navigation(ROUTES.WK_DASHBOARD);
                  handleCloseRequest();
                }}
              >
                <div className='button-text'>Ok</div>
              </button>
              <div className='spacer-sm'></div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop='static'
          className='modal-small'
        >
          <Modal.Body>
            <div className='spacer-lg'></div>
            <div className='top-icon'>
              <CheckCircleIcon />
            </div>
            <div className='spacer-sm'></div>

            {valueOk === 'ok' ? <h4>Consent requested</h4> : <h4>&nbsp;</h4>}

            <p>
              {valueOk === 'ok'
                ? 'Successfully sent a consent request to the client'
                : 'Support service details have been shared with the client'}
            </p>
            <div className='spacer-lg'></div>
            <div className='input-element-small text-center'>
              <button
                className='primary-button-small'
                onClick={() => {
                  handleClose();
                  routeTo(ROUTES.WK_DASHBOARD);
                }}
              >
                <div className='button-text'>Ok</div>
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <div className='container inner-page '>
          <div className='spacer-sm'></div>
          <div className='d-flex flex-column'>
            <div className='row justify-content-center'>
              <p>&nbsp;</p>
              <div className='col-lg-7 col-md-7 position-relative'>
                <div className='d-flex align-items-center justify-space-between'>
                  <div
                    className='left-round-button'
                    onClick={() => {
                      routeTo(ROUTES.WK_DASHBOARD);
                    }}
                  >
                    <ArrowLongLeftIcon />
                  </div>
                  <div style={{ width: '100%' }}>
                    <UserHeader title='Request Support' admin='Worker' />
                  </div>
                </div>

                <div className='steps'>
                  {step === 0 && <img src='/images/step-0.png' />}
                  {step === 1 && <img src='/images/step-1.png' />}
                  {step === 2 && <img src='/images/step-2.png' />}
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-7'>
                <div className='spacer-lg'></div>
                <div className='d-flex justify-content-center'>
                  {!issues && <Spinner animation='border' size='sm' />}
                </div>

                {acceptedList && step === 1 && (
                  <div>
                    {acceptedList.filter(item => item.AvailabilityFlag === true)
                      .length > 0 && (
                      <h4>
                        <strong> Accepted Support Services</strong>
                      </h4>
                    )}

                    {acceptedList.filter(item => item.AvailabilityFlag === true)
                      .length === 0 && (
                      <>
                        <div className='no-services text-center'>
                          <div className='icon-top'>
                            <InformationCircleIcon />
                          </div>
                          <p>Waiting for capacity </p>
                        </div>
                        <div className='spacer-lg'></div>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div
                            className='input-element-small text-center'
                            style={{ marginRight: 24 }}
                          >
                            <button
                              className='primary-button'
                              onClick={() => routeTo(ROUTES.WK_DASHBOARD)}
                            >
                              <div className='button-text'>Previous</div>
                            </button>
                          </div>
                          <div className='input-element-small text-center'></div>
                        </div>
                      </>
                    )}

                    <div className='spacer-sm'></div>
                    {acceptedList &&
                      acceptedList.filter(
                        item => item.AvailabilityFlag === true,
                      ).length > 0 &&
                      acceptedList
                        .filter(item => item.AvailabilityFlag === true)
                        .sort((a, b) => {
                          if (a.Name < b.Name) {
                            return -1;
                          }
                          if (a.Name > b.Name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((item, index) => (
                          <Disclosure
                            as='div'
                            className={'acc-wrapper open'}
                            key={index}
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className='d-flex acc-wrapper-button'
                                  onClick={() =>
                                    selectSupportService(item, index)
                                  }
                                >
                                  <div className='d-flex align-items-center'>
                                    {index === ssIndex ? (
                                      <div className='icon-check-circle-select'>
                                        <CheckCircleIcon />
                                      </div>
                                    ) : (
                                      <div className='icon-check-circle'></div>
                                    )}
                                    <span className='text-orange'>
                                      {item?.Name}
                                    </span>{' '}
                                  </div>
                                  <ChevronUpIcon
                                    className={`${open ? '' : 'rotate-180'}`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className='acc-panel'>
                                  <p>
                                    <span className='text-orange'>Name</span>
                                    <br />
                                    <span>{item.Name}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>Email</span>
                                    <br />
                                    <span>{item.Email}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>Mobile</span>
                                    <br />
                                    <span>{item.Mobile}</span>
                                  </p>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}

                    <p>&nbsp;</p>
                    {acceptedList &&
                      acceptedList.filter(
                        item => item.AvailabilityFlag === true,
                      ).length > 0 && (
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='input-element-small text-center'>
                            <button
                              className='primary-button'
                              onClick={() => routeTo(ROUTES.WK_DASHBOARD)}
                            >
                              <div className='right-icon'>
                                <ArrowLeftIcon />
                              </div>
                              <div className='button-text'>Previous</div>
                            </button>
                          </div>
                          &nbsp;&nbsp;&nbsp;
                          <div className='input-element-small text-center'>
                            <button
                              className='primary-button'
                              onClick={() => makeConsent()}
                            >
                              <div className='button-text'>Next</div>
                              <div className='right-icon'>
                                <ArrowRightIcon />
                              </div>
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                )}
                {step === 0 && (
                  <>
                    <div className='step-0'>
                      <h4>
                        <strong>Client Details</strong>
                      </h4>
                      <div className='input-element-small'>
                        <div className='input-normal'>
                          <label>Location</label>
                          <Autocomplete
                            required
                            placeholder='Client location'
                            options={mapOptions}
                            defaultValue=''
                            apiKey={googleMapAPIKEY}
                            onPlaceSelected={place => onPlaceSelected(place)}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='input-element-small'>
                            <div className='input-normal'>
                              <label>Name</label>
                              <input
                                required
                                type='text'
                                className='col-lg-12 col-md-12'
                                name='ClientName'
                                placeholder='Client Name'
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='input-element-small'>
                            <div className='input-normal'>
                              <label>Email Address</label>
                              <input
                                required
                                type='text'
                                className='col-lg-12 col-md-12'
                                name='ClientEmail'
                                placeholder='Client email address'
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className='input-element-small'>
                            <div className='input-normal'>
                              <label>Phone number</label>
                              <input
                                required
                                name='ClientMobile'
                                type='text'
                                placeholder='Client Phone number'
                                onChange={onChange}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='input-element-small'>
                            <div className='input-normal'>
                              <label>Age</label>
                              <input
                                required
                                maxLength={2}
                                type='text'
                                className='col-lg-12 col-md-12'
                                name='Age'
                                placeholder='Enter client age'
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='input-element-small'>
                            <label>Gender</label>

                            <div
                              className='d-flex justify-content-start align-items-center'
                              style={{
                                height: '48px',
                              }}
                            >
                              <div className='d-flex justify-content-center align-items-center mr-2'>
                                <input
                                  id='MALE'
                                  type='radio'
                                  name='Gender'
                                  vlaue='MALE'
                                  className='mr-2'
                                  onChange={onChange}
                                />{' '}
                                &nbsp;&nbsp;
                                <label for='MALE' className='mb-0'>
                                  Male
                                </label>
                              </div>
                              &nbsp;&nbsp;
                              <div className='d-flex justify-content-center align-items-center mr-2'>
                                <input
                                  id='FEMALE'
                                  type='radio'
                                  name='Gender'
                                  vlaue='FEMALE'
                                  className='mr-2'
                                  onChange={onChange}
                                />{' '}
                                &nbsp;&nbsp;
                                <label for='FEMALE' className='mb-0'>
                                  Female
                                </label>
                              </div>
                              &nbsp;&nbsp;
                              <div className='d-flex justify-content-center align-items-center mr-2'>
                                <input
                                  id='OTHER'
                                  type='radio'
                                  name='Gender'
                                  vlaue='OTHER'
                                  className='mr-2'
                                  onChange={onChange}
                                />{' '}
                                &nbsp;&nbsp;
                                <label for='OTHER' className='mb-0'>
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{' '}
                    <div className='spacer-md'></div>
                    <div className='step-0'>
                      <div className='input-element-small'>
                        <label>Select support type</label>
                        {issues && (
                          <select onChange={selectValue}>
                            <option value=''>Select support type</option>
                            {issues &&
                              issues.map(issue => (
                                <option
                                  key={issue.STID}
                                  value={JSON.stringify(issue)}
                                >
                                  {issue.Title}
                                </option>
                              ))}
                          </select>
                        )}
                      </div>
                    </div>
                    <div className='spacer-md'></div>
                    <div className='step-0'>
                      <div className='input-element-small'>
                        <div className='input-wrapper d-flex flex-column'>
                          <label>Additional information</label>
                          <textarea
                            className='col-lg-12 col-md-12'
                            name='AdditionalInfo'
                            placeholder=''
                            value={payload.AdditionalInfo}
                            onChange={onChange}
                            rows='10'
                            style={{
                              background: '#f1f1f1',
                              border: 'thin solid #d8d8d8',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                      <div className='input-element-small text-center'>
                        <button className='primary-button' onClick={goToNext}>
                          <div className='button-text'>Next</div>
                          <div className='right-icon'>
                            <ArrowRightIcon />
                          </div>
                        </button>
                      </div>
                    </div>
                  </>
                )}

                <div className='d-flex justify-content-center'>
                  {loading_proximity && <Spinner size='sm' />}
                </div>

                {support_serivces &&
                  support_serivces.length > 0 &&
                  support_serivces &&
                  step === 1 && (
                    <>
                      <div className='support-services-info'>
                        Support services
                        <p>{user_location && user_location}</p>
                        <p>{issueValue && issueValue.Title}</p>
                      </div>
                      {support_serivces &&
                        support_serivces.length > 0 &&
                        support_serivces.sort((a, b) => {
                          if (a.Name < b.Name) {
                            return -1;
                          }
                          if (a.Name > b.Name) {
                            return 1;
                          }
                          return 0;
                        }).map((ss, i) => (
                          <Disclosure
                            as='div'
                            className={
                              i === sIndex ? 'acc-wrapper open' : 'acc-wrapper'
                            }
                          >
                            {({ open = i === sIndex }) => (
                              <>
                                <Disclosure.Button
                                  className='d-flex acc-wrapper-button'
                                  onClick={() => updatePayload(ss, i)}
                                >
                                  <div className='d-flex align-items-center'>
                                    {currentSS.filter(
                                      c =>
                                        c['SSM-SWorkerID'] ===
                                        ss['SSM-SWorkerID'],
                                    ).length > 0 ? (
                                      <div className='icon-check-circle-select'>
                                        <CheckCircleIcon />
                                      </div>
                                    ) : (
                                      <div className='icon-check-circle'></div>
                                    )}
                                    <span className='text-orange'>
                                      {ss.Name} - {ss.Location}
                                    </span>{' '}
                                  </div>
                                  <ChevronUpIcon
                                    className={`${open ? '' : 'rotate-180'}`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className='acc-panel'>
                                  <p>
                                    <span className='text-orange'>Name</span>
                                    <br />
                                    <span>{ss.Name}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>Email</span>
                                    <br />
                                    <span>{ss.Email}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>Mobile</span>
                                    <br />
                                    <span>{ss.Mobile}</span>
                                  </p>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      <div className='spacer-lg'></div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div
                          className='input-element-small text-center'
                          style={{ marginRight: 24 }}
                        >
                          <button
                            className='primary-button'
                            onClick={() => prevStep(0)}
                          >
                            <div className='button-text'>Previous</div>
                          </button>
                        </div>
                        <div className='input-element-small text-center'>
                          <button
                            className='primary-button'
                            onClick={createInquiry}
                            disabled={progress}
                          >
                            <div className='button-text'>Request Capacity</div>
                            <div className='right-icon'>
                              {progress ? (
                                <Spinner animation='border' size='sm' />
                              ) : (
                                <ArrowRightIcon />
                              )}
                            </div>
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                {!loading_proximity &&
                  issueValue &&
                  (!support_serivces || support_serivces.length === 0) &&
                  step === 1 && (
                    <>
                      <div className='no-services text-center'>
                        <div className='icon-top'>
                          <InformationCircleIcon />
                        </div>
                        <p>No support services currently available</p>
                      </div>
                      <div className='spacer-lg'></div>
                      <div className='d-flex justify-content-center align-items-center'>
                        <div
                          className='input-element-small text-center'
                          style={{ marginRight: 24 }}
                        >
                          <button
                            className='primary-button'
                            onClick={() => prevStep(0)}
                          >
                            <div className='button-text'>Previous</div>
                          </button>
                        </div>
                        <div className='input-element-small text-center'></div>
                      </div>
                    </>
                  )}

                {step === 2 && (
                  <form onSubmit={sendConsentForInquiry}>
                    <div className='row'>
                      <div className='col-md-12 mb-4'>
                        <div
                          className={`input-element-small ${
                            validator.TenantName ? 'error' : ''
                          }`}
                        >
                          <div className='input-normal'>
                            <label>
                              Client Name
                              {validator.TenantName ? (
                                <span>
                                  <small>Please enter client name</small>
                                </span>
                              ) : (
                                ''
                              )}
                            </label>
                            <input
                              value={selectedSupportRequest?.ClientName}
                              required
                              name='TenantName'
                              autoFocus
                              type='text'
                              placeholder='Name'
                              disabled
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div
                          className={`input-element-small ${
                            validator.TenantMobile ? 'error' : ''
                          }`}
                        >
                          <div className='input-normal'>
                            <label>Client Phone number</label>
                            <input
                              value={selectedSupportRequest?.ClientMobile}
                              required
                              name='TenantMobile'
                              type='text'
                              disabled
                              placeholder='Phone number'
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='input-element-small'>
                          <div className='input-normal'>
                            <label>Client Email</label>
                            <input
                              value={selectedSupportRequest?.ClientEmail}
                              required
                              name='TenantEmail'
                              type='email'
                              placeholder='Email address'
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                    <p
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      Send a SMS to the client to confirm the client's consent
                      to sharing the information with a third party
                    </p>

                    <span className='badge bg-warning py-4 px-2 mb-4 d-block'>
              
                         <p className='m-0'>
                         Select <strong>
                         No</strong> to send service details to the client but not make a referral
                         </p>
                          
                      
                    </span>

                    <div className='input-element-small d-flex justify-content-center'>
                      <div
                        className='input-element-small-ok d-flex align-items-center'
                        onClick={() => switchOk('ok')}
                      >
                        {valueOk === 'ok' && <CheckCircleIcon />}
                        {valueOk !== 'ok' && (
                          <div className='check-circle'></div>
                        )}
                        <label for='ok'>Yes</label>
                      </div>
                      &nbsp;
                      <div
                        className='input-element-small-ok d-flex align-items-center'
                        onClick={() => switchOk('not-ok')}
                      >
                        {valueOk === 'not-ok' && <CheckCircleIcon />}
                        {valueOk !== 'not-ok' && (
                          <div className='check-circle'></div>
                        )}
                        <label for='Dont'>No</label>
                        
                      </div>
                    </div>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className='d-flex justify-content-center align-items-center'>
                      <div
                        className='input-element-small text-center'
                        style={{ marginRight: 24 }}
                      >
                        <button
                          className='primary-button'
                          onClick={() => prevStep(1)}
                        >
                          <div className='button-text'>Previous</div>
                        </button>
                      </div>
                      <div className='input-element-small text-center'>
                        <button
                          type='submit'
                          className='primary-button'
                          disabled={progress}
                        >
                          <div className='button-text'>
                            {progress ? (
                              <Spinner animation='border' size='sm' />
                            ) : (
                              'Finish'
                            )}
                          </div>
                          <div className='right-icon'>
                            <ArrowRightIcon />
                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default PMRaiseIssue;
