import { ROUTES } from 'core/routes';
import { getUser } from 'core/plugins/Entry';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const AuthLayout = ({ children }) => {
  const navigation = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const routeTo = url => {
    navigation(url);
  };

  React.useEffect(() => {
    if (getUser().UUID === undefined) {
      toast.error('Login required to continue');
   //   routeTo(ROUTES.INDEX);
      return;
    }
  }, [navigation, routeTo]);

  return <>{children}</>;
};

export default AuthLayout;
