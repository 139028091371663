import {
  Amplify,
  Auth,
} from 'aws-amplify';
import { toast } from 'react-toastify';
//
import { apiVerifyUserCode } from 'core/api';
//You can get the current config object
export const AWSCognito = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: "ap-southeast-2:fe3fd61e-84c1-48ae-906f-6f0a43da7895",
      region: "ap-southeast-2",
      userPoolId: "ap-southeast-2_NpY1hpake", 
      userPoolWebClientId: "77kcv3cr996eovogid23nf0cpa",
      storage: sessionStorage
    }
  })
}
export const confirmSignUp = async (payload) => {
  const { email, code } = payload
  try {
    let response = await Auth.confirmSignUp(email, code)
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}
export const signIn = async (payload) => {
  const { username, password, type } = payload
  try {
    const user = await Auth.signIn(username, password)    
    const tempUser = {
      type,
      UUID: user.username,
      attributes: user.attributes
    }
    return { user, tempUser, requireVerification: true }
  } catch (error) {
    toast.error(error?.message)
    return error
  }
}
export const verifyUserCode = async (tempUser, challengeAnswer) => {
  try {
    const response = await apiVerifyUserCode(tempUser.UUID, challengeAnswer)
    if (response.success) {
      setUser(tempUser)
      return true
    } else {
      return false
    }
  } catch (error) {
    toast.error("Error verifying code: " + error.message)
    return false
  }
}
export const verifyUA = (email) => {
  Auth.verifyUserAttribute(email, "email")
    .then((result) => {
      toast.success("Invitation email sent successfully")
    })
    .catch((error) => {
      toast.error("Error sending invitation email:", error)
    })
}
export const signUp = async (payload) => {
  const { name, password, email, phone_number } = payload
  try {
    const response = await Auth.signUp({
      username: email,
      password,
      attributes: {
        name,
        email,
        phone_number
      },
      autoSignIn: {
        enabled: true
      }
    })
    return response
  } catch (error) {
    toast.error(error?.message)
    return false
  }
}
export const signOut = async () => {
  try {
    const response = await Auth.signOut()
    clearUser()
    window.location = "/"

    return response
  } catch (error) {
    console.log("error signing out: ", error)
  }
}
export const setUser = (payload) => {
  sessionStorage.setItem("SSMCognitoQ4355463435PM", JSON.stringify(payload))
}
export const getUser = () => {
  const user = sessionStorage.getItem("SSMCognitoQ4355463435PM")
  if (user) {
    return JSON.parse(user)
  } else {
    return false
  }
}
// clear user
export const clearUser = () => {
  sessionStorage.removeItem("SSMCognitoQ4355463435PM")
}
//auth forgot password
export const forgotPassword = async (username) => {
  try {
    const response = await Auth.forgotPassword(username).then((data) => {
      toast.success("Password reset instructions sent to your email address")
    })

    return response
  } catch (error) {
    return error
  }
}
//auth change password
export const changePassword = async (payload) => {
  const { username, code, new_password } = payload
  try {
    const response = await Auth.forgotPasswordSubmit(
      username,
      code,
      new_password
    )
    return response
  } catch (error) {
    return error
  }
}
//
export const changePasswordImmediately = async (payload) => {
  const { user, newPassword, username } = payload;
  try {
    const response = await Auth.completeNewPassword(
      user,
      newPassword,
      {
        name: username,
        phone_number: '+61419773225'
      }
    )
    toast.success("Password changed successfully");

    return response
  } catch (error) {

    toast.error(error?.message);
    return error
  }
}