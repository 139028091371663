import './core/styles/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AppLanding from 'components/pages';
import OrganisationLanding from 'components/pages/OA';
import AddServiceWorker from 'components/pages/OA/add-pm';
import ListServiceWorkers from 'components/pages/OA/list-pm';
import ForgotPassword from 'components/pages/forgot';
import IALanding from 'components/pages/IA';
import AddAgencyAdmin from 'components/pages/IA/add-agency-admin';
import AddSupportService from 'components/pages/IA/add-support-service';
import IADashboard from 'components/pages/IA/dashboard';
import AgencyAdminList from 'components/pages/IA/list-agencies';
import SupportServiceList from 'components/pages/IA/list-support-service';
import Logout from 'components/pages/logout';
import PMLanding from 'components/pages/WK';
import PMDashboard from 'components/pages/WK/dashboard';
import PMRaiseIssue from 'components/pages/WK/raise-issue';
import { AWSCognito, getUser, signOut } from 'core/plugins/Entry';
import { ROUTES } from 'core/routes';
import { useIdleTimer } from 'react-idle-timer';
import { PrivacyPage } from 'components/privacy';
import { TermsPage } from 'components/terms-and-conditions';
import OrganisationDashboard from 'components/pages/OA/dashboard';
import ThankYou from 'components/pages/thank-you';
import AppCollection from 'components/app-collections';
import IARaiseIssues from 'components/pages/IA/raise-issues';
import AppFooter from 'components/shared/footer';

function App() {
  const [Auth, setAuth] = React.useState(null);

  React.useEffect(() => {
    AWSCognito();
    setAuth(getUser());
  }, []);

  const onIdle = async () => {
    await signOut();
    setAuth(null);
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: onIdle,
  });

  return (
    <article className='App'>
      <ToastContainer
        position='bottom-center'
        autoClose={3000}
        hideProgressBar={true}
        theme='colored'
      />
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.INDEX} element={<AppLanding />}></Route>
          <Route path={ROUTES.LOGOUT} element={<Logout />}></Route>
          <Route path={ROUTES.FORGOT} element={<ForgotPassword />}></Route>
          <Route path={ROUTES.THANK_YOU} element={<ThankYou />}></Route>
          <Route path={ROUTES.IA_LOGIN} element={<IALanding />}></Route>
          <Route path={ROUTES.IA_DASHBOARD} element={<IADashboard />}></Route>
          <Route path='/IA/raise-issues' element={<IARaiseIssues/>}></Route>
          <Route
            path='/IA/add-support-service'
            element={<AddSupportService />}
          ></Route>
          <Route
            path='/IA/support-service'
            element={<SupportServiceList />}
          ></Route>
          <Route
            path='/IA/agency-admin-list'
            element={<AgencyAdminList />}
          ></Route>
          <Route
            path='/IA/add-agency-admin'
            element={<AddAgencyAdmin />}
          ></Route>
          <Route
            path={ROUTES.OA_LOGIN}
            element={<OrganisationLanding />}
          ></Route>
          <Route
            path={ROUTES.OA_DASHBOARD}
            element={<OrganisationDashboard />}
          ></Route>
          <Route
            path={ROUTES.OA_ALL_PMS}
            element={<ListServiceWorkers />}
          ></Route>
          <Route path={ROUTES.OA_ADD_PM} element={<AddServiceWorker />}></Route>

          <Route path={ROUTES.WK_LOGIN} element={<PMLanding />}></Route>
          <Route path={ROUTES.WK_DASHBOARD} element={<PMDashboard />}></Route>
          <Route
            path={ROUTES.WK_RAISE_ISSUE}
            element={<PMRaiseIssue />}
          ></Route>

          <Route path='/privacy-policy' element={<AppCollection />}></Route>
          <Route path='/privacy' element={<PrivacyPage />}></Route>
          <Route path='/terms-and-conditions' element={<TermsPage />}></Route>

          <Route path='*'>
            <>Page Not found</>
          </Route>
        </Routes>
      </BrowserRouter>
      <AppFooter />
    </article>
  );
}

export default App;
