import React from 'react';

import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon, FlagIcon } from '@heroicons/react/24/outline';

import { apiGetAllInquires, apiGetAllIssues, apiGetPM } from 'core/api';
import { getUser } from 'core/plugins/Entry';
import { ROUTES } from 'core/routes';
import { UserHeader } from 'components/shared';
import AuthLayout from 'components/layouts/AuthLayout';
const PMDashboard = () => {
  const navigate = useNavigate();

  const routeTo = url => {
    navigate(url);
  };
  const [issues, setIssues] = React.useState(false);
  const [inquiries, setInquiry] = React.useState(false);
  const [loading, setLoaing] = React.useState(false);
  React.useEffect(() => {
    setLoaing(true);
    apiGetPM(getUser().UUID)
      .then(response => {
        if (response && response.WID) {
          apiGetAllIssues().then(response => {
            setIssues(response);
          });
          apiGetAllInquires().then(response => {
            if (response) {
              setLoaing(false);

              let allWorkerRespondedTickets = response.filter(
                a =>
                  a?.ClientConsent === undefined &&
                  a?.AvailabilityFlag === true,
              ).sort((a,b)=> new Date(b.CreateDateTime) - new Date(a.CreateDateTime));

              let allCapacityWaitingTickets = response.filter(
                a =>
                  a?.ClientConsent === undefined &&
                  a?.AvailabilityFlag === undefined,
              ).sort((a,b)=> new Date(b.CreateDateTime) - new Date(a.CreateDateTime));

              let allConsentWaitingTickets = response.filter(
                a => a?.ClientConsent === 'Waiting',
              ).sort((a,b)=> new Date(b.CreateDateTime) - new Date(a.CreateDateTime));

              let allCapacityAcceotedTickets = response.filter(
                a =>
                  a?.ClientConsent === 'Yes' &&
                  a?.AvailabilityFlag === true,
              ).sort((a,b)=> new Date(b.CreateDateTime) - new Date(a.CreateDateTime));

              let allCapacityNotAcceotedTickets = response.filter(
                a =>
                  a?.ClientConsent === 'No' &&
                  a?.AvailabilityFlag === true,
              ).sort((a,b)=> new Date(b.CreateDateTime) - new Date(a.CreateDateTime));

              let allCapacityRejectedTickets = response.filter(
                a =>
                  a?.ClientConsent === undefined &&
                  a?.AvailabilityFlag === false,
              ).sort((a,b)=> new Date(b.CreateDateTime) - new Date(a.CreateDateTime));

              setInquiry(
                allWorkerRespondedTickets.concat(
                  allCapacityWaitingTickets,
                  allCapacityRejectedTickets,
                  allConsentWaitingTickets,
                  allCapacityAcceotedTickets,
                  allCapacityNotAcceotedTickets,
                ),
              );
            }
          });
        } else {
          navigate('/logout');
        }
      })
      .finally(() => {
        //setLoaing(false);
      });
  }, []);

  const [sIndex, setSIndex] = React.useState(false);
  const viewAcceptedServices = async supportRequest => {
    localStorage.setItem('supportRequest', JSON.stringify(supportRequest));
    // eslint-disable-next-line no-template-curly-in-string
    navigate(
      `/WK/raise-issues?accepted-services&CID=${supportRequest.ClientID}`,
    );
  };
  return (
    <>
      <AuthLayout>
        <div className='container inner-page'>
          <div className='spacer-lg'></div>
          <div className='d-flex flex-column'>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-7'>
                <UserHeader admin='Worker' />
                <div className='spacer-sm'></div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div
                      className='user-button user-button-small user-button-small-special'
                      onClick={() => routeTo(ROUTES.WK_RAISE_ISSUE)}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='icon-left fill-gradient-orange'>
                          <img src='/images/support-icon-box.png' alt='' />
                        </div>
                        <div className='user-button-text'>Request Support</div>
                      </div>
                      <div className='icon-right'>
                        <ArrowRightIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-7'>
                <div className='accordian-list'>
                  <div className='d-flex sub-title-with-icon align-items-center'>
                    <div className='icon-left'>
                      <FlagIcon />
                    </div>
                    <h3>
                      Support History {loading ? <Spinner size='sm' /> : ''}
                    </h3>
                  </div>
                  <p>&nbsp;</p>
                  <div className='support-services-info'>
                    <div>
                      {inquiries &&
                        inquiries.map((inq, i) => (
                          <Disclosure
                            as='div'
                            key={i}
                            className={
                              sIndex === i ? 'acc-wrapper open' : 'acc-wrapper'
                            }
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className='d-flex align-items-center justify-content-between acc-wrapper-button'
                                  onClick={() => {
                                    setSIndex(i);
                                  }}
                                >
                                  <span className='text-orange flex-grow-1'>
                                    Ticket No &nbsp; TC &nbsp; 
                                    {inq['SSM-InquiryID'].substr(0, 4)} - &nbsp;
                                    {moment(inq.CreateDateTime).format(
                                      'YYYY-MMM-DD',
                                    )}
                                  </span>

                                  {inq?.ClientConsent === undefined &&
                                    inq?.AvailabilityFlag === true && (
                                      <span className='inline-flex items-center status-text-badge status-text-badge-success'>
                                        <span>Capacity </span> <br />
                                        received
                                      </span>
                                    )}

                                  {inq?.ClientConsent === 'Waiting' && (
                                    <span className='inline-flex items-center status-text-badge status-text-badge-warning'>
                                      <span className='status-circle status-orange'></span>
                                      <span>Consent</span> <br />
                                      pending
                                    </span>
                                  )}

                                  {inq?.ClientConsent === 'Yes' &&
                                    inq?.AvailabilityFlag === true && (
                                      <span className='inline-flex items-center status-text-badge status-text-badge-success'>
                                        <span> Consent</span>
                                        <br />
                                        approved
                                      </span>
                                    )}

                                  {inq?.ClientConsent === 'No' &&
                                    inq?.AvailabilityFlag === true && (
                                      <span className='inline-flex items-center status-text-badge status-text-badge-error'>
                                        <span>Consent</span>
                                        <br />
                                        rejected
                                      </span>
                                    )}

                                  {inq?.ClientConsent === undefined &&
                                    inq?.AvailabilityFlag === undefined && (
                                      <span className='inline-flex items-center status-text-badge status-text-badge-warning'>
                                        <span>Capacity </span> <br />
                                        waiting
                                      </span>
                                    )}

                                  {inq?.ClientConsent === undefined &&
                                    inq?.AvailabilityFlag === false && (
                                      <span className='inline-flex items-center status-text-badge status-text-badge-error'>
                                        <span>Capacity</span>
                                        <br />
                                        rejected
                                      </span>
                                    )}

                                  <ChevronUpIcon
                                    className={`h-5 w-5 ${
                                      open ? '' : 'transform rotate-180'
                                    }`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel
                                  className={
                                    open ? 'acc-panel open' : 'acc-panel'
                                  }
                                >
                                  <p>
                                    <span className='text-orange'>
                                      Created Date
                                    </span>
                                    <br />
                                    <span>
                                      {inq?.CreateDateTime
                                        ? moment(inq?.CreateDateTime).format(
                                            'YYYY-MMM-DD',
                                          )
                                        : '-'}
                                      &nbsp;
                                      {inq?.CreateDateTime
                                        ? moment(inq?.CreateDateTime).format(
                                            'hh:mm A',
                                          )
                                        : '-'}
                                    </span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Client Name
                                    </span>
                                    <br />
                                    <span>{inq?.ClientName}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Client's Age
                                    </span>
                                    <br />
                                    <span>
                                      {inq?.ClientAge ? inq.ClientAge : '-'}
                                    </span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Client's Gender
                                    </span>
                                    <br />
                                    <span>
                                      {inq?.ClientGender
                                        ? inq.ClientGender
                                        : '-'}
                                    </span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Client Email
                                    </span>
                                    <br />
                                    <span>{inq?.ClientEmail}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Client Phone Number
                                    </span>
                                    <br />
                                    <span>{inq?.ClientMobile}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Client's Location
                                    </span>
                                    <br />
                                    <span>{inq?.ClientLocation}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Requested support type
                                    </span>
                                    <br />
                                    <span> {inq.IssueTitle} </span>
                                  </p>
                                  <p>
                                    <span className='text-orange'>Status</span>
                                    <br />
                                    {inq?.ClientConsent === undefined &&
                                    inq?.AvailabilityFlag === true && (
                                    
                                        <span>Capacity
                                        received
                                      </span>
                                    )}


                                    {inq?.ClientConsent === undefined &&
                                      inq?.AvailabilityFlag === undefined && (
                                        <span>Capacity waiting</span>
                                      )}

                                    {inq?.ClientConsent === 'Yes' &&
                                      inq?.AvailabilityFlag === undefined && (
                                        <span>Consent approved</span>
                                      )}

                                    {inq?.ClientConsent === 'Waiting' &&
                                      inq?.AvailabilityFlag === undefined && (
                                        <span>Consent pending</span>
                                      )}

                                    {inq?.ClientConsent === 'No' &&
                                      inq?.AvailabilityFlag === undefined && (
                                        <span>Consent rejected</span>
                                      )}

                                    {inq?.ClientConsent === undefined &&
                                      inq?.AvailabilityFlag === false && (
                                        <span>Capacity rejected</span>
                                      )}
                                  </p>

                                  <p>
                                    <span className='text-orange'>
                                      Contextual Notes
                                    </span>
                                    <br />
                                    <span>{inq?.ContextualNotes}</span>
                                  </p>

                                  {inq?.SWName && (
                                    <p>
                                      <span className='text-orange'>
                                        Support service
                                      </span>
                                      <br />
                                      <span>
                                        {inq?.SWName ? inq?.SWName : '-'}
                                      </span>
                                    </p>
                                  )}

                                  {inq?.ClientConsent === undefined &&
                                    inq?.AvailabilityFlag === undefined && (
                                      <div className='input-element-small text-center'>
                                        <button
                                          className='primary-button'
                                          onClick={() =>
                                            viewAcceptedServices(inq)
                                          }
                                        >
                                          <div className='button-text'>
                                            View
                                          </div>
                                        </button>
                                      </div>
                                    )}

{inq?.ClientConsent === undefined &&
                                    inq?.AvailabilityFlag === true && (
                                    
                                      <div className='input-element-small text-center'>
                                        <button
                                          className='primary-button'
                                          onClick={() =>
                                            viewAcceptedServices(inq)
                                          }
                                        >
                                          <div className='button-text'>
                                            View
                                          </div>
                                        </button>
                                      </div>
                                    )}

                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}

                      {inquiries && inquiries.length === 0 && (
                        <div className='empty-list-data'>
                          <div className='spacer-sm'></div>
                          <h4>No issues found</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default PMDashboard;
