export const ROUTES = {
  INDEX: "/",
  FORGOT: "/forgot-password",
  THANK_YOU:'/request/thank-you',
  PRIVACY: "/privacy",
  TERMS: "/terms",
  LOGOUT:'/logout',

  WK_DASHBOARD: "/WK/dashboard",
  WK_RAISE_ISSUE: "/WK/raise-issues",
  WK_LOGIN: "/WK/login",

  IA_DASHBOARD: "/IA/dashboard",
  IA_LOGIN: "/IA/login",
  IA_ALL_AGENCIES: "/IA/all-agencies",
  IA_ALL_SUPPORT_SERVICES: "/IA/all-support-serivces",
  IA_ADD_AGENCY: "/IA/add-agency",
  IA_ADD_SUPPORT_SERVICE: "/IA/add-support-servivce",

  OA_DASHBOARD: "/OA/dashboard",
  OA_LOGIN: "/OA/login",
  OA_ALL_PMS: "/OA/list-service-workers",
  OA_ADD_PM: "/OA/add-service-workers"
}
