import React from 'react';
const AppFooter = () => {
  return (
    <>
      
      <div className='footer-layout'>
        <p className=''>
          <a href='/privacy-policy'>Privacy</a>  | 
          <a href='/terms-and-conditions'>Terms & Conditions</a>
        </p>
      </div>
    </>
  );
};

export default AppFooter;
