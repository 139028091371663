import React from 'react';

import { Badge, Button, Modal, Spinner } from 'react-bootstrap';
import Autocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Disclosure } from '@headlessui/react';
import { ArrowLongLeftIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  FlagIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { PencilIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';

import {
  apiDeleteSupportWorkers,
  apiGetAllIssues,
  apiGetSupportServices,
  apiUpdateSupportWorkers,
} from 'core/api';
import { googleMapAPIKEY, mapOptions } from 'core/plugins/config';
import {
  convertAustralianNumber,
  isAustralianNumber,
} from 'core/plugins/validator';
import { ROUTES } from 'core/routes';
function SupportServiceList() {
  const navigation = useNavigate();

  const routeTo = route => {
    navigation(route);
  };
  const [payload, setPayload] = React.useState(false);
  const filter = e => {
    setPayload(e.target.value);
    let filtered = list.filter(item => item.Name.includes(e.target.value));
    setList(filtered);

    if (e.target.value.length === 0) {
      clearFilters();
      setPayload(false);
      setList(defaultList);
    }
  };
  const [list, setList] = React.useState(false);
  const [defaultList, setDefaultList] = React.useState(false);

  const clearFilters = () => {
    setPayload(false);

    apiGetSupportServices().then(response => {
      setLoading(false);
      setList(response);
      setDefaultList(response);
    });
  };

  const [services, setServices] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    apiGetSupportServices().then(response => {
      setLoading(false);
      setList(response);
      setDefaultList(response);
    });

    apiGetAllIssues().then(response => {
      if (response) {
        setServices(response);
      }
    });
  }, []);

  const mapServices = current_issues => {
    let issues = JSON.parse(current_issues);
    // console.log(issues);
    let current_services = [];
    if (services && issues.length) {
      services &&
        services.forEach(s => {
          issues.forEach(i => {
            if (s.STID === i) {
              current_services.push(s);
            }
          });
        });

      return current_services;
    } else {
      return [];
    }
  };

  const [user_location, setUserLocation] = React.useState(false);

  const onPlaceSelected = place => {
    if (place && place?.formatted_address) {
      setUserLocation(place.formatted_address);

      setEditPayload(prevState => ({
        ...prevState,
        Location: place.formatted_address,
      }));
    }
  };

  const [editPayload, setEditPayload] = React.useState({
    Mobile: '',
    Name: '',
    Email: '',
    Location: '',
    STID: [],
    Gender: '',
    AgeMin: '',
    AgeMax: '',
  });
  const [editLoader, setEditLoader] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(false);
  const submitEdit = e => {
    e.preventDefault();

    if (
      editPayload.Mobile.length > 0 &&
      !isAustralianNumber(editPayload.Mobile)
    ) {
      toast.error(
        'Please enter a valid Australian phone number eg +61234567890 / 0298765432',
      );
      return;
    }

    if (editPayload.IssueID && editPayload.IssueID.length === 0) {
      toast.error('Please select a servivce');
      return;
    }

    setEditLoader(true);

    apiUpdateSupportWorkers({
      'SSM-SWorkerID': editPayload['SSM-SWorkerID'],
      Mobile: convertAustralianNumber(editPayload.Mobile),
      Name: editPayload.Name,
      Email: editPayload.Email,
      AgeMin: editPayload.AgeMin,
      AgeMax: editPayload.AgeMax,
      Location: editPayload.Location,
      Gender: editPayload.Gender,
      STID: editPayload.STID,
    }).then(response => {
      apiGetSupportServices().then(response => {
        setList(response);
        toast.success('Support service updated');
        setEditLoader(false);
      });
      handleCloseEditModal();
    });
  };

  const [showEditModal, setShowEditModal] = React.useState(false);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const onChangeEditPaylod = e => {
    setEditPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const deleteSW = item => {
    setDeleteLoader(true)
    apiDeleteSupportWorkers(item['SSM-SWorkerID']).then(response => {
      toast.success('Support service deleted successfully');
      apiGetSupportServices().then(response => {
        setList(response);
        setDeleteLoader(false);
        showDeleteConfirmation(false);
      });
    });
  };

  const updateEditPayload = e => {
    let IssueIDs = editPayload.STID || [];

    if (IssueIDs && !IssueIDs.includes(e.target.value)) {
      setEditPayload(prevState => ({
        ...prevState,
        STID: [...IssueIDs, e.target.value],
      }));
    } else {
      setEditPayload(prevState => ({
        ...prevState,
        STID: IssueIDs.filter(i => i !== e.target.value),
      }));
    }
  };

  const updateGender = e => {
   
    let s_genders = editPayload.Gender;
    if (s_genders.includes(e.target.value)) {
      setEditPayload(prevState => ({
        ...prevState,
        Gender: s_genders.filter(i => i !== e.target.value),
      }));
    } else {
      setEditPayload(prevState => ({
        ...prevState,
        Gender: [...s_genders, e.target.value],
      }));
    }
  };

  const [deleteConfirmationModel,showDeleteConfirmation] = React.useState(false);
const [deleteLoader, setDeleteLoader] = React.useState(false);
  return (
    <>
     <Modal show={deleteConfirmationModel} onHide={() => showDeleteConfirmation(false)} backdrop="static" className="verification-modal"
       centered>
        <Modal.Header>
          <Modal.Title>Are you sure to delete this Service ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {
              deleteConfirmationModel && deleteConfirmationModel.Name
            }
          </p>
        </Modal.Body>
      
        <Modal.Footer>
        <div className='input-element'>
        <button onClick={()=>deleteSW(deleteConfirmationModel)} 
          className='btn btn-edit'
              disabled={deleteLoader}
            >
              {deleteLoader ? <Spinner animation='border' size='sm' /> : 'Yes'}
          </button>
          <div className="spacer-sm"></div>
          <button onClick={()=>showDeleteConfirmation(false)} 
          className='btn btn-delete'>
            No
          </button>
          </div>
      
        </Modal.Footer>
      </Modal>
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        className='edit-modal'
        size='lg'
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Support Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={submitEdit}>
            <div className='input-element'>
              <div className='input-normal'>
                <input
                  autoFocus
                  value={editPayload.Name}
                  name='Name'
                  type='text'
                  placeholder='Service Name'
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className='input-element'>
              <div className='input-normal'>
                <input
                  value={editPayload.Email}
                  name='Email'
                  disabled
                  type='email'
                  placeholder='Email'
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>

            <div className='input-element'>
              <div className='input-normal'>
                <input
                  value={editPayload.Mobile}
                  name='Mobile'
                  type='text'
                  placeholder='Mobile'
                  required
                  onChange={onChangeEditPaylod}
                ></input>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='input-element'>
                  <div className='input-normal'>
                    <input
                      style={{ minWidth: 0 }}
                      value={editPayload.AgeMin}
                      name='AgeMin'
                      type='number'
                      placeholder='Age Min'
                      required
                      maxLength={2}
                      onChange={onChangeEditPaylod}
                    ></input>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='input-element'>
                  <div className='input-normal'>
                    <input
                      style={{ minWidth: 0 }}
                      value={editPayload.AgeMax}
                      name='AgeMax'
                      type='number'
                      placeholder='Age Max'
                      required
                      maxLength={2}
                      onChange={onChangeEditPaylod}
                    ></input>
                  </div>
                </div>
              </div>
            </div>

            <div className='input-element'>
              <div className='input-normal'>
                <Autocomplete
                  options={mapOptions}
                  required
                  defaultValue={editPayload.Location}
                  apiKey={googleMapAPIKEY}
                  onPlaceSelected={place => onPlaceSelected(place)}
                />
              </div>
            </div>

            <div
              className=''
              style={{
                height: 300,
                overflowY: 'scroll',
              }}
            >
              <label>Supported Services</label>

              {services &&
                services.map(service => (
                  <>
                    <div className='service-item pb-2'>
                      <Badge bg='light'>
                        <input
                          onChange={updateEditPayload}
                          type='checkbox'
                          value={service.STID}
                          id={service.STID}
                          checked={
                            (editPayload?.STID &&
                              editPayload.STID.includes(service.STID)) ||
                            false
                          }
                        />
                        <label for={service.STID}>{service.Title}</label>
                      </Badge>
                    </div>
                  </>
                ))}
            </div>
            <p>&nbsp;</p>
           
            <label>Supported Genders</label>
            <div className='gender-items'>
              <div className='gender-item'>
                <Badge bg='light'>
                  <input
                    id='male'
                    value='MALE'
                    type='checkbox'
                    onChange={updateGender}
                    checked={
                      (editPayload?.Gender &&
                        editPayload.Gender.includes('MALE')) ||
                      false
                    }
                  ></input>
                  <label htmlFor='male'>Male</label>
                </Badge>
              </div>
              <div className='gender-item'>
                <Badge bg='light'>
                  <input
                    value='FEMALE'
                    id='female'
                    type='checkbox'
                    onChange={updateGender}
                    checked={
                      (editPayload?.Gender &&
                        editPayload.Gender.includes('FEMALE')) ||
                      false
                    }
                  ></input>
                  <label htmlFor='female'>Female</label>
                </Badge>
              </div>
              <div className='gender-item'>
                <Badge bg='light'>
                  <input
                    id='other'
                    value='OTHER'
                    type='checkbox'
                    checked={
                      (editPayload?.Gender &&
                      editPayload.Gender.includes('OTHER')) ||
                      false
                    }
                    onChange={updateGender}
                  ></input>
                  <label htmlFor='other'>Other</label>
                </Badge>
              </div>
            </div>
            <div className='spacer-sm'></div>
            <div className='input-element text-center'>
              <button
                type='submit'
                className='primary-button primary-button-small no-animation'
              >
                <div className='button-text'>
                  {editLoader ? (
                    <Spinner animation='border' size='sm' />
                  ) : (
                    'Update'
                  )}
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div className='container inner-page '>
        <div className='spacer-lg'></div>
        <div className='d-flex flex-column'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-7'>
              <p className='admin-type'>inCommunity Admin</p>
            </div>
            <div className='col-lg-7 col-md-7 d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                <div
                  className='left-round-button'
                  onClick={() => routeTo(ROUTES.IA_DASHBOARD)}
                >
                  <ArrowLongLeftIcon />
                </div>
                <h2 className='text-left'>Support services</h2>
              </div>
              <div className='input-element text-center'>
                <button
                  onClick={() => routeTo('/IA/add-support-service')}
                  type='submit'
                  className='primary-button button-add'
                >
                  <div className='button-text'></div>
                  <div className='right-icon'>
                    <PlusIcon />
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-7'>
              <div className='spacer-lg'></div>
              <div className='accordian-list'>
                <div className='d-flex sub-title-with-icon align-items-center'>
                  <div className='icon-left'>
                    <FlagIcon />
                  </div>
                  <h3>
                    Support services{' '}
                    {loading ? <Spinner animation='border' size='sm' /> : ''}
                  </h3>
                </div>
                <div className='spacer-sm'></div>
                <div className=''>
                  <div>
                    <div className='search-bar'>
                      <div className='search-input'>
                        <input
                          value={payload ? payload : ''}
                          onChange={filter}
                          type='text'
                          placeholder='Search support service'
                        ></input>
                        <div className='right-icon' onClick={clearFilters}>
                          {payload && payload.length > 0 ? (
                            <XMarkIcon />
                          ) : (
                            <MagnifyingGlassIcon />
                          )}
                        </div>
                      </div>
                    </div>

                    {list &&
                      list.sort((a,b)=>{
                        if (a.Name < b.Name) {
                          return -1;
                        }
                        if (a.Name > b.Name) {
                          return 1;
                        }
                        return 0;
                      }).map((item, i) => (
                        <Disclosure
                          as='div'
                          className={
                            sIndex === i ? 'acc-wrapper open' : 'acc-wrapper'
                          }
                          key={item.id}
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className='d-flex acc-wrapper-button'
                                onClick={() => setSIndex(i)}
                              >
                                <div>
                                  <span className='text-orange'>
                                    {item.Name}
                                  </span>
                                </div>
                                <ChevronUpIcon
                                  className={`${open ? '' : 'rotate-180'}`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className='acc-panel'>
                                <p>
                                  <span className='text-orange'>Name</span>
                                  <br />
                                  <span>{item.Name}</span>
                                </p>

                                <p>
                                  <span className='text-orange'>Email</span>
                                  <br />
                                  <span>{item.Email} </span>
                                </p>

                                <p>
                                  <span className='text-orange'>Mobile</span>
                                  <br />
                                  <span>{item.Mobile}</span>
                                </p>

                                <p>
                                  <span className='text-orange'>Location</span>
                                  <br />
                                  <span>{item.Location}</span>
                                </p>

                                <p>
                                  <span className='text-orange'>
                                    Services Offered
                                  </span>
                                  <br />
                                  <span>
                                    {item.STID &&
                                      mapServices(item.STID).map(s => (
                                        <>
                                          {s.Title} <br />
                                        </>
                                      ))}
                                  </span>
                                </p>

                                <p>
                                  <span className='text-orange'>
                                    Supported age range
                                  </span>
                                  <br />

                                  <span>
                                    {item.AgeMin} - {item.AgeMax}
                                  </span>
                                </p>

                                <p>
                                  <span className='text-orange'>
                                    Supported gender
                                  </span>
                                  <br />

                                  <div className='gender-list'>
                                    {JSON.parse(item.Gender).includes(
                                      'MALE',
                                    ) && <Badge bg='light'>Male</Badge>}

                                    {JSON.parse(item.Gender).includes(
                                      'FEMALE',
                                    ) && <Badge bg='light'>Female</Badge>}

                                    {JSON.parse(item.Gender).includes(
                                      'OTHER',
                                    ) && <Badge bg='light'>Other</Badge>}
                                  </div>
                                </p>
                                <div className='row justify-content-center'>
                                  <div className='col-md-12 d-flex justify-content-center'>
                                    <div>
                                      <Button
                                        className='btn-edit'
                                        onClick={() => {
                                          setShowEditModal(true);
                                          setEditPayload(prevState => ({
                                            ...item,
                                            STID: [...JSON.parse(item.STID)],
                                            Gender: JSON.parse(item.Gender),
                                          }));
                                        }}
                                      >
                                        <span>
                                          <PencilIcon />
                                        </span>
                                        Edit
                                      </Button>
                                      <Button
                                        className='btn-delete'
                                        onClick={() =>
                                          showDeleteConfirmation(item)
                                        }
                                      >
                                        <span>
                                          <TrashIcon />
                                        </span>{' '}
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportServiceList;
