import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiRequestHandler } from 'core/api';
import { toast } from 'react-toastify';
import { ROUTES } from 'core/routes';

const ThankYou = () => {
  const location = useLocation();

  const [payload, setPayload] = React.useState({
    Availability: null,
    SWRequestID: null,
  });

  React.useEffect(() => {
    if (location.search.includes('available=Y')) {
      setPayload(prevState => ({
        ...prevState,
        Availability: true,
      }));
    } else if (location.search.includes('available=N')) {
      setPayload(prevState => ({
        ...prevState,
        Availability: false,
      }));
    }

    if (location.search.split('requestID=')[1]) {
      setPayload(prevState => ({
        ...prevState,
        SWRequestID: location.search.split('requestID=')[1],
      }));
    }
  }, [location]);

  const [success, setSuccess] = React.useState(null);
  React.useEffect(()=>{

      if(payload.SWRequestID){
        apiRequestHandler(payload).then(response=>{
          toast.success('Availability status updated successfully !');
          setSuccess(true)
        })
        .catch(error=>{
          setSuccess(false)
          toast.error('Error updating status of the availability')
        })
      }

  },[payload]);

  const navigate = useNavigate();

  const routeTo = (path) =>{
    navigate(path)
  }
  return (
    <div className='container login-form-page '>
      <div className='spacer-lg'></div>
      <div className='spacer-lg'></div>
      <div className='d-flex flex-column justify-content-center'>
        <div className='row justify-content-center'>
          <div className='col-md-8 text-center'>
            
            {
              success === null && <>

               <h2 className='text-center'>Please wait... </h2>
            <h4>Updating request</h4>
            </>
            }

            { success && 
              <>
              <div className='main-icon'>
              <div className='spacer-sm'></div>
              <div className='top-icon' style={{color:'#2FB27E'}}>
                <CheckCircleIcon />
              </div>
            </div>
               <h2 className='text-center'>Thank You ! </h2>
            <h4>Your availability request is updated! </h4>
              </>
            }

            {
              success === false && <>
              <div className='main-icon'>
              <div className='spacer-sm'></div>
              <div className='top-icon' style={{color:'red'}}>
                <ExclamationCircleIcon />
              </div>
            </div>
               <h2 className='text-center' style={{color:'red'}}>Request not updated! </h2>
            <h4>Error updating your availability request! </h4>
              </>
            }
           
            <div className='spacer-lg'></div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-4 col-md-4'>
            <div className='input-element'>
              {/* <label>Username</label> */}
              <div className='input-wrapper'>
                <div className='left-icon'>
                  <UserIcon />
                </div>
              </div>
            </div>

            <div className='spacer-sm'></div>

            <div className='input-element text-center'>
              <button type='submit' onClick={()=>routeTo(ROUTES.INDEX)} className='primary-button'>
                <div className='button-text'>Ok</div>
              </button>
            </div>

            <div className='spacer-lg'></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
