import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUser } from 'core/plugins/Entry';
import { Disclosure } from '@headlessui/react';
import { apiGetAllInquiresOA, apiGetPMA } from 'core/api';
import { UserHeader } from '../../shared';
import { ROUTES } from 'core/routes';
import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  ChevronUpIcon,
  FlagIcon,
} from '@heroicons/react/24/outline';
import { ArrowUpTrayIcon, FunnelIcon } from '@heroicons/react/24/solid';
function OrganisationDashboard() {
  const [inquiries, setInquiry] = React.useState(false);

  React.useEffect(() => {
    apiGetPMA(getUser().UUID).then(response => {
      if (response && response.SSMOID) {
        apiGetAllInquiresOA().then(response => {
          setInquiry(response);
        });
      } else {
        toast.warning('Not a valid Organisation Administrator');
      }
    });
  }, []);

  const navigate = useNavigate();

  const routeTo = url => {
    navigate(ROUTES.OA_ALL_PMS);
  };

  const [sIndex, setSIndex] = React.useState(false);

  const [filterModal, setFilterModal] = React.useState(false);
  const [allfilters, setAllFilters] = React.useState(false);
  const [start_date, setStartDate] = React.useState(false);
  const [end_date, setEndDate] = React.useState(false);
  const [filters, setFilters] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pms, setFiltersPM] = React.useState(false);
  const [pmas, setFiltersPMA] = React.useState(false);
  const filterTickets = false;
  const generateReport = () => {};
  const clearAll = () => {};
  return (
    <>
      <Modal
        className='filter-modal'
        show={filterModal}
        onHide={() => {
          setFilterModal(false);
        }}
      >
        <Modal.Body>
          <h2>Filter by</h2>

          <div className='d-flex filter-date-wapper justify-content-between'>
            <div className='datepicker-wrapper'>
              <div className='picker-icon'>
                <CalendarDaysIcon />
              </div>

              <div className='date-wrapper'>
                <small>From date</small>
                <DatePicker
                  selected={allfilters.start_date}
                  onChange={setStartDate}
                />
              </div>
            </div>

            <div className='datepicker-wrapper'>
              <div className='picker-icon'>
                <CalendarDaysIcon />
              </div>
              <div className='date-wrapper'>
                <small>To date</small>
                <DatePicker
                  selected={allfilters.end_date}
                  onChange={setEndDate}
                />
              </div>
            </div>
          </div>
          <div className='spacer-md'></div>
          <div>
            <form>
              <div className='input-element'>
                <div className='input-normal'>
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    Workers
                  </div>

                  <select onChange={setFilters}>
                    <option>Workers</option>
                    {pms &&
                      pms.map(pm => <option value={pm.WID}>{pm.Name}</option>)}
                  </select>
                </div>
              </div>

              <div className='input-element'>
                <div className='input-normal'>
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    Organisations
                  </div>

                  <select onChange={setFiltersPMA}>
                    <option>Select Organisation</option>
                    {pmas &&
                      pmas.map(PMA => (
                        <option value={PMA.SSMOID}>{PMA.CompanyName}</option>
                      ))}
                  </select>
                </div>
              </div>
            </form>

            <div className='input-element text-center'>
              <button
                className='primary-button no-animation'
                onClick={() => {
                  setFilterModal(false);
                  filterTickets();
                }}
              >
                <div className='button-text'>Filter</div>
              </button>
              <div className='spacer-sm'></div>
              <div
                onClick={clearAll}
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                Reset Filters{' '}
                {loading ? <Spinner animation='border' size='sm' /> : ''}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className='container inner-page '>
        <div className='spacer-lg'></div>
        <div className='d-flex flex-column'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-7'>
              <UserHeader admin='Organisation Admin' />
              <div className='spacer-lg'></div>
              <div className='user-button user-button-small' onClick={routeTo}>
                <div className='d-flex align-items-center'>
                  <div className='icon-left'>
                    <img src='/images/worker-icon1.png' alt='' />
                  </div>
                  <div className='user-button-text'>Workers</div>
                </div>
                <div className='icon-right'>
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-7'>
              <div className='spacer-sm'></div>
              <div className='accordian-list'>
               <div className='d-flex' style={{
                justifyContent: 'space-between',
                alignItems: 'center'
               }}>
               <div className='d-flex sub-title-with-icon align-items-center'>
                  <div className='icon-left'>
                    <FlagIcon />
                  </div>
                  <h3>Enquiries {!inquiries && <Spinner size='sm' />}</h3>
                </div>
                <div>
                  {/* <div className='input-element text-center d-flex'>
                    <button
                      disabled={!inquiries?.data && !inquiries?.data?.length}
                      className='primary-button primary-button-xsmall no-animation'
                      onClick={() => setFilterModal(true)}
                      style={{
                        marginRight: 12,
                      }}
                    >
                      <div className='right-icon'>
                        <FunnelIcon />
                      </div>
                      <div className='button-text'>Filter</div>
                    </button>

                    <div className='download-button'>
                      <button
                        onClick={generateReport}
                        disabled={!inquiries?.data && !inquiries?.data?.length}
                      >
                        {loading ? <Spinner size='sm' /> : <ArrowUpTrayIcon />}
                      </button>
                    </div>
                  </div> */}
                </div>
               </div>

                <div>
                  {inquiries &&
                    inquiries.map((inq, i) => (
                      <Disclosure
                        as='div'
                        key={i}
                        className={
                          sIndex === i ? 'acc-wrapper open' : 'acc-wrapper'
                        }
                      >
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className='d-flex align-items-center justify-content-between acc-wrapper-button'
                              onClick={() => {
                                setSIndex(i);
                              }}
                            >
                              <span className='text-orange flex-grow-1'>
                                Ticket No TC &nbsp; {inq['SSM-InquiryID'].substr(0,4)}
                                {moment(inq.CreateDateTime).format(
                                  'YYYY-MMM-DD',
                                )}
                              </span>

                              <ChevronUpIcon
                                className={`h-5 w-5 ${
                                  open ? '' : 'transform rotate-180'
                                }`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel
                              className={open ? 'acc-panel open' : 'acc-panel'}
                            >
                              <p>
                                <span className='text-orange'>
                                  Created Date and Time
                                </span>
                                <br />
                                <span>
                                  {inq?.CreateDateTime
                                    ? moment(inq?.CreateDateTime).format(
                                        'YYYY-MMM-DD',
                                      )
                                    : '-'}
                                    &nbsp;
                                     {inq?.CreateDateTime
                                    ? moment(inq?.CreateDateTime).format(
                                        'hh:mm A',
                                      )
                                    : '-'}
                                </span>
                              </p>

                              <p>
                                <span className='text-orange'>Client Name</span>
                                <br />
                                <span>{inq?.ClientName}</span>
                              </p>


                              <p>
                                <span className='text-orange'>Worker Name</span>
                                <br />
                                <span>{inq?.WorkerName}</span>
                              </p>

                              

                              <p>
                                <span className='text-orange'>
                                  Client's Age
                                </span>
                                <br />
                                <span>
                                  {inq?.ClientAge ? inq.ClientAge : '-'}
                                </span>
                              </p>

                              <p>
                                <span className='text-orange'>
                                  Client's Gender
                                </span>
                                <br />
                                <span>
                                  {inq?.ClientGender ? inq.ClientGender : '-'}
                                </span>
                              </p>

                              <p>
                                <span className='text-orange'>
                                  Client Email
                                </span>
                                <br />
                                <span>{inq?.ClientEmail}</span>
                              </p>

                              <p>
                                <span className='text-orange'>
                                  Client Phone Number
                                </span>
                                <br />
                                <span>{inq?.ClientMobile}</span>
                              </p>
                              <p>
                                <span className='text-orange'>
                                  Client's Location
                                </span>
                                <br />
                                <span>{inq?.ClientLocation}</span>
                              </p>

                              <p>
                                <span className='text-orange'>
                                  Requested support type
                                </span>
                                <br />
                                <span> {inq.IssueTitle} </span>
                              </p>

                              <p>
                                <span className='text-orange'>
                                  Contextual Notes
                                </span>
                                <br />
                                <span>{inq?.ContextualNotes}</span>
                              </p>

                              {inq?.SWName && (
                                <p>
                                  <span className='text-orange'>
                                    Support service
                                  </span>
                                  <br />
                                  <span>{inq?.SWName}</span>
                                </p>
                              )}
                              {inq?.SWEmail && (
                                <p>
                                  <span className='text-orange'>
                                    Support email
                                  </span>
                                  <br />
                                  <span>{inq?.SWEmail}</span>
                                </p>
                              )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  {inquiries && inquiries.length === 0 && (
                    <div className='empty-list-data'>
                      <div className='spacer-sm'></div>
                      <h4>No issues found</h4>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganisationDashboard;
