import {httpClientAxios } from "../interceptors"
import { getUser } from "../plugins/Entry"

const API = {
  ISSUES_GET_ALL: "/issues",
  ISSUES_GET_ALL_2: `/Issues/GetAllIssues`,

  ENQUIRY_CREATE: `/Inquiry`,
  ENQUIRY_GET_ALL: `/Inquiry`,
  ENQUIRY_GENERATE: (pm_id) => `/Inquiry?id=${pm_id}`,

  SUPPORT_SERVICES_GET_ALL: `/SupportWorkers`,
  SUPPORT_SERVICES_UPDATE: `/SupportWorkers`,
  SUPPORT_SERVICES_DELETE: `/SupportWorkers`,
  SUPPORT_SERVICES_GET_ISSUES: (SID) => `/SupportWorkers?id=${SID}`
}

export const apiGetAllIssues = async () => {
  try {
    const response = await httpClientAxios.get("/ST/GetAllST")
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllInquires = async () => {
  try {
    const response = await httpClientAxios.get(
      `/Inquiry/GetAllEnquiries?ID=${getUser().UUID}`
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllInquiresOA = async () => {
  try {
    const response = await httpClientAxios.get(
      `/Inquiry/GetAllEnquiriesOrganisation?ID=${getUser().UUID}`
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}



export const apiGetAllInquiresIA = async (currentPage = 1) => {
  try {
    const response = await httpClientAxios.get(
      `/Inquiry/GetAllEnquiriesIA?pagesize=${10}&page=${currentPage}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
export const apiGetSupportServices = async () => {
  try {
    const response = await httpClientAxios.get(
      "/SupportWorkers/GetAllSupportWorkers"
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiGenerateReport = async () => {
  try {
    const response = await httpClientAxios.get("/Inquiry/GenerateReport")
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllRequests = async (payload) =>{
  try {
    const response = await httpClientAxios.get(`/SWRequest/GetAll?ID=${payload}`)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
export const apiGetSupportServicesDistance = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      "/SupportWorkers/GetDistance",
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiRequestCapacity = async (payload) =>{
  try{
      const response = await httpClientAxios.post("/SWRequest/CreateRequests", payload);
      return response
  }catch(error){
    console.log(error);
  }
}

export const apiCreateClient = async (payload) =>{
  try{

    const response = await httpClientAxios.post("/Client/CreateClient", payload);
    return response;

  }catch(error){
    console.log(error);
  }
}

export const apiRequestHandler = async (payload) =>{

  try{
    const response = httpClientAxios.post("/SWRequest/Handler", payload);
    return response;
  } catch(error){
    console.log(error);
  }

}

export const apiClientConsentHandler = async (payload) =>{
  try{
    const response = await httpClientAxios.post("/Client/ConsentHandler", payload);
    return response;
  }catch(error){
    console.log(error);
  }

}


export const apiCreateInquiry = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      "/Inquiry/CreateInquiry",
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

//Workder
export const apiCreatePM = async (payload) => {
  try {
    const response = await httpClientAxios.post("/Worker/CreateWorker", payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiUpdatePM = async (payload) => {
  try {
    const response = await httpClientAxios.post(`/Worker/UpdateWorker`, payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllPM = async () => {

  try {
    const response = await httpClientAxios.get(
      `/Worker/GetAllWorker?ID=${getUser().UUID}`
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
  ///
}

export const apiGetPM = async (PMID) => {
  try {
    const response = await httpClientAxios.get(
      `/Worker/GetWorker?ID=${getUser().UUID}`
    )
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}
export const apiGetAllPMs = async () => {
  try {
    const response = await httpClientAxios.get(`/Worker/GetAllWorker`)
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
  ///
}

export const apiDeletePM = async (id) => {
  try {
    const response = await httpClientAxios.delete(`/Worker/DeleteWorker?ID=${id}`)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}


//Organisation
export const apiGetPMA = async (PMAID) => {
  try {
    const response = await httpClientAxios.get(`/Organisation/GetOrganisation?ID=${PMAID}`)
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiGetAllPMA = async () => {
  try {
    const response = await httpClientAxios.get(`/Organisation/GetAllOrganisation`)
    return response?.data?.message
  } catch (error) {
    console.log(error)
  }
}

export const apiUpdatePMA = async (payload) => {
  try {
    const response = await httpClientAxios.post(`/Organisation/UpdateOrganisation`, payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiCreatePMA = async (payload) => {
  try {
    const response = await httpClientAxios.post(`/Organisation/CreateOrganisation`, payload)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}
export const apiDeletePMA = async (id) => {
  try {
    const response = await httpClientAxios.delete(
      `/Organisation/DeleteOrganisation?ID=${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

//SupportWorkers

export const apiCreateSupportWorkers = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      `/SupportWorkers/CreateSupportWorker`,
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiGetSupportWorkers = async () => {
  try {
    const response = await httpClientAxios.get(`/SupportWorkers/GetAllSupportWorkers`)
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiDeleteSupportWorkers = async (id) => {
  try {
    const response = await httpClientAxios.delete(
      `/SupportWorkers/DeleteSupportWorkers?ID=${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiUpdateSupportWorkers = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      `/SupportWorkers/UpdateSupportWorkers`,
      payload
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

//get IA
export const apiGetIA = async (IAID) => {
  try {
    const response = await httpClientAxios.get(
      `/admin/getia?ID=${IAID}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
  }
}

export const apiSendUserInvitation = async (payload) => {
  try {
    const response = await httpClientAxios.post(
      `/Invitation/InviteUser`,
      payload
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const apiVerifyUserCode = async (userName, challengeAnswer) => {
  try {
    const response = await httpClientAxios.get(
      `/Auth/VerifyChallenge?userName=${encodeURIComponent(userName)}&challengeAnswer=${encodeURIComponent(challengeAnswer)}`
    )
    return response?.data;
  } catch (error) {
    console.log(error)
  }
}
