import Layout from "components/layouts/Layout";
import React from "react";

export const PrivacyPage = () => {
  return (
    <Layout>
      <article className="container py-4 static-content-page">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h1>
              <strong>
              inCommunity Inc Privacy Policy
              </strong>
            </h1>
            <section>
              <p>
                This document is the Privacy Policy of inCommunity Inc ABN 65
                653 625 605 (referred to as “inCommunity”; “we”, “us” and “our”)
                and sets out how we collect, use, disclose and otherwise handle
                your personal information. <br/><br/>
                
                 We are committed to protecting and
                maintaining your personal information in compliance with our
                obligations under the Australian Privacy Principles and the
                Privacy Act 1988 (Cth) (“Privacy Act”) <br/> <br/> By submitting your
                personal information to us, using our website and app, or
                accessing our services, you agree to the collection, use,
                disclosure and handling of your personal information in
                accordance with this Privacy Policy
              </p>
            </section>

            <section>
              <h2>1. The kinds of personal information we collect and hold</h2>
              <p>
                We may collect information from you when you visit or use our
                website or app, use our services or submit your personal
                information to us. Personal information we collect and hold may
                include, but is not limited to:
              </p>
              <ul>
                <li>
                  your name and contact details, including your email address,
                  postal address and phone number;
                </li>
                <li>
                  other information reasonably relevant to the provision of our
                  services, including your age, birthdate, profession,
                  occupation and job title;
                </li>
                <li>
                  any other personal information you provide to us in relation
                  to your access to our website, app or our services.
                </li>
              </ul>
              <p>
                When visiting or using our website, app or accessing our
                services, we may also collect and store information relating to
                such use and access including your IP address, your browser
                type, details of the pages or content you accessed on our
                website or app and time spent and information searched on our
                website or app.
              </p>
            </section>

            <section>
              <h2>2. How we collect your personal information</h2>
              <p>
                We collect personal information through lawful and fair means.
                We ordinarily only collect information directly from you, unless
                it is unreasonable or impracticable to do so. We may collect
                personal information from you in the following ways:
              </p>
              <ul>
                <li>through your access and use of our website or app;</li>
                <li>
                  when interacting or communicating with us, including in
                  person, by telephone, letter, fax or email;
                </li>
                <li>
                  when accessing or using our services, including completing of
                  any enrolment or other application forms. From time to time,
                  we may obtain or collect personal information about you from
                  third party sources, including:
                </li>
                <li>
                  other organisations or persons where you have given your
                  consent to the disclosure of your personal information;
                </li>
                <li>your representatives;</li>
                <li>
                  our referral partners or organisation we work with to deliver
                  our services.
                </li>
              </ul>
              <h2>
                3. Data security and how we hold and store personal information
              </h2>
              We hold personal information in a number of ways, which may
              include:
              <ul>
                <li>
                  as part of records and other electronic documents on which
                  personal information is contained;
                </li>
                <li>
                  on our information technology systems and servers, including
                  those operated by third parties who provide services to us in
                  connection with our operations and services; and
                </li>
                <li>
                  by securely storing hard copy documents, at our various
                  premises and using third party document management and
                  archiving services.
                </li>
              </ul>
              <p>
                We will take reasonable steps to protect all personal
                information which we hold from misuse, interference, loss and
                from unauthorised access, modification or disclosure. <br/><br/>
                You should be aware that, when using our website or app, no data
                transmission over the internet can be guaranteed as completely
                secure. <br/><br/>
                
                 We do not warrant the security of any information you
                transmit to us over the internet and you do so at your own risk.<br/><br/>
                We will continue to store and hold your personal information
                indefinitely, until such time as we no longer need it for any
                purpose for which the information may be used or disclosed under
                this Privacy Policy or under applicable privacy or data
                protection laws or (if earlier, and subject to the next
                paragraph) a reasonable time after you ask us to delete it.
                <br/><br/>
                We are located in Australia and all personal information we hold
                and store will remain in Australia unless otherwise advised by
                us.
              </p>
              <h2>
                4. The purposes for which we collect, hold, use and disclose
                personal information
              </h2>
              <p>
              We will collect, hold, use and disclose your personal information
              for the purpose of interacting with you, performing our activities
              and functions and providing our services to you. This includes,
              without limitation:
              </p>
            <ul>
               <li>to provide you with use and access to our
              website, our app and our services </li>
              <li> to communicate and interact
              with you in relation to our services, including to answer or
              attend to your enquiries and to provide you with information and
              advice about our services; </li>
              <li> internal management purposes,
              including to update our records and keep your contact details up
              to date; </li>
              <li> for business and service development and marketing
              purposes; or </li>
              <li> as otherwise permitted or required by law (such as
              to comply with obligations under the Privacy Act in relation to
              mandatory data breach notification)/ We may also disclose your
              personal information to: </li>
              <li> third parties that assist us in providing services to you; </li>
              <li> third parties who provide services that are requested for or by you; </li>
              <li> parties to whom you authorise us to disclose your personal information; </li>
              <li> government and regulatory authorities, as required or authorised by law; </li>
              <li> ourprofessional advisors; or </li>
              <li> other third parties permitted by law.</li>
            </ul>
              <h2>5. Access to and correction of your personal information</h2>
              <p>
                We take reasonable steps to ensure that the personal information
                we collect, use and disclose is accurate, complete and
                up-to-date. <br/><br/> You may request access to or correction of any
                personal information we hold about you by using the contact
                details set out below. <br/><br/>An access fee may be charged to cover our
                costs of providing the requested information to you. In certain
                circumstances, we may refuse to provide you with access or to
                correct your personal information including, but not limited to
                where:
              </p>
              <ul>
                <li>
                  giving access would pose a serious threat to the life, health
                  or safety of any individual, or to public health or public
                  safety
                </li>
                <li>
                  giving access would have an unreasonable impact on the privacy
                  of other individuals
                </li>
                <li>the request for access is frivolous or vexatious</li>
                <li> giving access would be unlawful or </li>
                <li>
                  denying access is otherwise required or authorised by law.
                </li>
              </ul>
              <p>
                Where we do not provide you with access to your personal
                information, we will provide you with the reason for denying
                access.
              </p>
              <h2>6. Opting out of promotional and marketing content</h2>
              <p>
                You may elect to opt-out of receiving direct marketing and
                promotional communications by contacting us using the contact
                details provided below, or by any other simple means to opt-out
                that we provide you. <br/><br/> However, some of our services may include a
                direct marketing and promotional communications feature as part
                of the service which cannot be removed and, as such, you may not
                be able to access or use some of our website or services if you
                elect to opt-out of all direct promotional and marketing
                communications.
              </p>
              <h2>7. Third Party Sites</h2>
              <p>
                Our website and app may contain links and pointers to other
                websites maintained by third party providers. These links are
                provided for your convenience only. These third party websites
                are not under our control and we do not accept responsibility
                for such sites (including the suitability of those sites for
                your intended use). You are responsible for reading the privacy
                policies or terms and conditions of those third party websites.
              </p>
              <h2>8. Complaints and contact details</h2>
              <p>
                If you have any queries in relation to this Privacy Policy or
                have any questions, comments or complaints regarding our
                practices you may contact us by email at
                <a href="mailto:privacy@incommunity.com.au">privacy@incommunity.com.au</a> We ask that any complaint should be
                made first in writing to us. <br/><br/> We will then respond to your
                complaint in writing and in accordance with any timeframes
                required by law. <br/><br/> We may request you to provide further
                information about your complaint to duly assess your complaint.
                For information about privacy generally, or if your concerns are
                not resolved to your satisfaction, you may contact the Office of
                the Australian Information Commissioner at <a href="http://www.oaic.gov.au" target="_blank" rel="noreferrer">www.oaic.gov.au</a> and
                on  <br/><a href="tel:1300 363 992">1300 363 992</a>.
              </p>
              <h2>9. Changes to this Privacy Policy</h2>
              <p>
                We reserve the right to change, modify or update this Privacy
                Policy at any time, without liability to you, by publication on
                our website or as otherwise notified to you. You will be bound
                by the changes immediately upon notification or publication,
                unless otherwise set out in this Privacy Policy or the notice. 
                <br/>
                <br/>
             <em>   This Privacy Policy was last updated on 17 January 2024.</em>
              </p>
            </section>
          </div>
        </div>
      </article>
    </Layout>
  );
};
