/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  ArrowRightIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import {
  ArrowLeftIcon,
  ArrowLongLeftIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';

import { apiGetAllRequests } from 'core/api';

import { ROUTES } from 'core/routes';
import AuthLayout from 'components/layouts/AuthLayout';
import { UserHeader } from 'components/shared';
const IARaiseIssues = () => {
  const [acceptedList, setAcceptedList] = React.useState(false);
  const location = useLocation();

  const navigation = useNavigate();
  const routeTo = url => {
    navigation(url);
  };

  const getAllRequests = async () => {
    const clientID = location.search.split('CID=')[1];
    await apiGetAllRequests(clientID).then(response => {
      setAcceptedList(response?.message);
    });
  };

  React.useEffect(() => {
      getAllRequests();
  }, [location]);


  const [ssIndex, setSSIndex] = React.useState(false);
  const selectSupportService = (item, index) => {
    setSSIndex(index);
  };

  return (
    <>
      <AuthLayout>
        <div className='container inner-page '>
          <div className='spacer-sm'></div>
          <div className='d-flex flex-column'>
            <div className='row justify-content-center'>
              <p>&nbsp;</p>
              <div className='col-lg-7 col-md-7 position-relative'>
                <div className='d-flex align-items-center justify-space-between'>
                  <div
                    className='left-round-button'
                    onClick={() => {
                      routeTo(ROUTES.IA_DASHBOARD);
                    }}
                  >
                    <ArrowLongLeftIcon />
                  </div>
                  <div style={{ width: '100%' }}>
                    <UserHeader
                      title='Support services'
                      admin='inCommunity Admin'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-7'>
                <div className='spacer-lg'></div>

                {acceptedList && (
                  <div>
                    {acceptedList.filter(item => item.AvailabilityFlag === true)
                      .length > 0 && (
                      <h4>
                        <strong> Accepted Support Services</strong>
                      </h4>
                    )}

                    {acceptedList.filter(item => item.AvailabilityFlag === true)
                      .length === 0 && (
                      <>
                        <div className='no-services text-center'>
                          <div className='icon-top'>
                            <InformationCircleIcon />
                          </div>
                          <p>Waiting for capacity </p>
                        </div>
                        <div className='spacer-lg'></div>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div
                            className='input-element-small text-center'
                            style={{ marginRight: 24 }}
                          >
                            <button
                              className='primary-button'
                              onClick={() => routeTo(ROUTES.WK_DASHBOARD)}
                            >
                              <div className='button-text'>Previous</div>
                            </button>
                          </div>
                          <div className='input-element-small text-center'></div>
                        </div>
                      </>
                    )}

                    <div className='spacer-sm'></div>
                    {acceptedList &&
                      acceptedList.filter(
                        item => item.AvailabilityFlag === true,
                      ).length > 0 &&
                      acceptedList
                        .filter(item => item.AvailabilityFlag === true)
                        .map((item, index) => (
                          <Disclosure
                            as='div'
                            className={'acc-wrapper open'}
                            key={index}
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className='d-flex acc-wrapper-button'
                                  onClick={() =>
                                    selectSupportService(item, index)
                                  }
                                >
                                  <div className='d-flex align-items-center'>
                                    {index === ssIndex ? (
                                      <div className='icon-check-circle-select'>
                                        <CheckCircleIcon />
                                      </div>
                                    ) : (
                                      <div className='icon-check-circle'></div>
                                    )}
                                    <span className='text-orange'>
                                      {item?.Name}
                                    </span>{' '}
                                  </div>
                                  <ChevronUpIcon
                                    className={`${open ? '' : 'rotate-180'}`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className='acc-panel'>
                                  <p>
                                    <span className='text-orange'>Name</span>
                                    <br />
                                    <span>{item.Name}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>Email</span>
                                    <br />
                                    <span>{item.Email}</span>
                                  </p>

                                  <p>
                                    <span className='text-orange'>Mobile</span>
                                    <br />
                                    <span>{item.Mobile}</span>
                                  </p>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}

                    <p>&nbsp;</p>
                    {acceptedList &&
                      acceptedList.filter(
                        item => item.AvailabilityFlag === true,
                      ).length > 0 && (
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className='input-element-small text-center'>
                            <button
                              className='primary-button'
                              onClick={() => routeTo(ROUTES.IA_DASHBOARD)}
                            >
                              <div className='right-icon'>
                                <ArrowLeftIcon />
                              </div>
                              <div className='button-text'>Previous</div>
                            </button>
                          </div>
                          &nbsp;&nbsp;&nbsp;
                         
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default IARaiseIssues;
