import React, { useState } from 'react';

import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ArrowRightIcon,
  InformationCircleIcon,
  LockClosedIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

import AppFooter from '../../shared/footer';
import { apiGetPM } from '../../../core/api';
import { signIn, verifyUserCode } from '../../../core/plugins/Entry';
import { ROUTES } from '../../../core/routes';

function PMLanding() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [payload, setPayload] = React.useState({
    username: '',
    password: '',
    type: 'PM',
  });

  const onChange = e => {
    setPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onConfirmPayloadChange = e => {
    setConformPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [confirm, setConformPayload] = React.useState({
    email: '',
    code: '',
  });

  const [showConfirmUser, setShowConfirmUser] = React.useState(false);

  const openConfirmUserModal = () => {
    setShowConfirmUser(true);
  };

  const handleConfirmUserModalClose = () => {
    setShowConfirmUser(false);
  };

  const submitConfirmUser = () => {
    handleConfirmUserModalClose();
  };

  const [agreed, setAgreed] = React.useState(
    localStorage.getItem('userAgreedToTerms') === 'true',
  );

  const handleAgreementChange = e => {
    const isAgreed = e.target.checked;
    setAgreed(isAgreed);
    localStorage.setItem('userAgreedToTerms', isAgreed);
  };

  const [tempUser, setTempUser] = useState(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);

  const handleVerificationCodeChange = e => {
    setVerificationCode(e.target.value);
  };
  const [isVerifing, setVerifing] = React.useState(false);
  const submitVerificationCode = async () => {
    if (!verificationCode || verificationCode === '') {
      toast.error('Verification code required');
      return;
    }
    setLoading(true);

    setVerifing(true);
    try {
      const verified = await verifyUserCode(tempUser, verificationCode);
      if (verified) {
        apiGetPM(tempUser.UUID).then(response => {
          if (response && response.WID) {
            toast.success(`Welcome`);
            navigate(ROUTES.WK_DASHBOARD);
            setVerifing(false);
            setLoading(false);
          } else {
            setVerifing(false);
            setLoading(false);
            toast.warn('User is not a Support Service Worker');
          }
        });
        setShowVerificationModal(false);
      } else {
        setVerifing(false);
        setLoading(false);
        toast.error('Invalid verification code');
      }
    } catch (error) {
      setLoading(false);
      setVerifing(false);
      toast.error('Error verifying code');
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const login = e => {
    e.preventDefault();
    if (!agreed) {
      toast.warn('Please agree to the Privacy and Terms & Conditions');
      return;
    }
    setLoading(true);
    setTimeout(() => {
      signIn(payload).then(response => {
        if (response.message === 'User is not confirmed.') {
          openConfirmUserModal();
          setLoading(false);
        }
        if (response.requireVerification && response.user.username) {
          setTempUser(response.tempUser);
          setShowVerificationModal(true);
        }
      })
      .finally(()=>{
        setLoading(false);

      });
    }, 1000);
  };
  return (
    <>
      <Modal
        show={showConfirmUser}
        onHide={handleConfirmUserModalClose}
        backdrop='static'
        className='confirm-modal'
      >
        <Modal.Body>
          <div className='spacer-lg'></div>
          <div className='top-icon'>
            <InformationCircleIcon />
          </div>
          <div className='spacer-sm'></div>
          <h4>User not confirmed.</h4>
          <p>
            Verify your email address <br />
            <small>
              Please click the verification link sent to your email address
            </small>
          </p>

          <div className='input-element text-center'>
            <button
              onClick={submitConfirmUser}
              className='primary-button-small'
            >
              <div className='button-text'>Close</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showVerificationModal}
        onHide={() => {setShowVerificationModal(false);
          setLoading(false);}}
        backdrop='static'
        className='verification-modal'
      >
        <Modal.Header>
          <Modal.Title>Verification Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the verification code sent to your email.</p>
          <div className='verification-input'>
            <div className='input-element'>
              <div className='input-wrapper'>
                <input
                  required
                  autoFocus
                  type='text'
                  value={verificationCode ? verificationCode : ''}
                  onChange={handleVerificationCodeChange}
                  placeholder='Enter code here'
                  className='form-control'
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='input-element'>
            <button
              onClick={submitVerificationCode}
              className='btn btn-primary primary-button'
              disabled={isVerifing}
            >
              {isVerifing ? <Spinner animation='border' size='sm' /> : 'Verify'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    
        <div className='container login-form-page '>
          <div className='d-flex flex-column justify-content-center'>
            <div className='row justify-content-center'>
              <div className='col-md-8 text-center'>
                <div className='spacer-lg'></div>

                <div className='main-icon'>
                  <img src='/images/worker-icon1.png' alt='' />
                </div>
                <h2 className='text-center'>
                  Login as a <br /> Worker
                </h2>
                <h4>Please sign in to continue</h4>
                <div className='spacer-lg'></div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-lg-4 col-md-4'>
                <form onSubmit={login}>
                  <div className='input-element'>
                    {/* <label>Username</label> */}
                    <div className='input-wrapper'>
                      <div className='left-icon'>
                        <UserIcon />
                      </div>
                      <input
                        autoFocus
                        name='username'
                        type='email'
                        placeholder='Email'
                        required
                        onChange={onChange}
                      ></input>
                    </div>
                  </div>

                  <div className='input-element'>
                    {/* <label>Password</label> */}
                    <div className='input-wrapper'>
                      <div className='left-icon'>
                        <LockClosedIcon />
                      </div>
                      <input
                        name='password'
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Password'
                        required
                        onChange={onChange}
                      ></input>
                      <div className='right-icon' onClick={togglePassword}>
                        {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                      </div>
                    </div>
                  </div>
                  <div className='spacer-sm'></div>
                  <div className='text-center privacy-terms-link-wrapper'>
                    <label>
                      <input
                        type='checkbox'
                        checked={agreed}
                        onChange={handleAgreementChange}
                      />
                      <p className=''>
                        I agree to the <a href='/privacy-policy'>Privacy</a> and{' '}
                        <a href='/terms-and-conditions'>Terms & Conditions</a>
                      </p>
                    </label>
                  </div>
                  <div className='spacer-lg'></div>

                  <div className='input-element text-center'>
                    <button
                      disabled={loading}
                      type='submit'
                      className='primary-button'
                    >
                      <div className='button-text'>
                        {loading ? (
                          <Spinner animation='border' size='sm' />
                        ) : (
                          'Login'
                        )}
                      </div>
                      <div className='right-icon'>
                        <ArrowRightIcon />
                      </div>
                    </button>
                  </div>
                </form>
                <div className='spacer-lg'></div>
                 <div className="d-block justify-content-center text-center forgot-password-link-wrapper">
        <a href={ROUTES.FORGOT}>Forgot Password ?</a>
      </div>
      <div className='spacer-lg'></div>
      <p
        className='text-center'
        style={{
          fontSize: '12px',
          color: '#888',
        }}
      >
        Refer Connet Assist by inCommunity
      </p>
              </div>
            </div>
          </div>
        </div>
     
    </>
  );
}

export default PMLanding;
