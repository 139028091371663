import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ArrowLongRightIcon } from '@heroicons/react/24/solid';

import { AWSCognito } from 'core/plugins/Entry';
import { ROUTES } from 'core/routes';
import AppFooter from 'components/shared/footer';
function AppLanding() {
  const navigate = useNavigate();

  const routeTo = url => {
    navigate(url);
  };
  AWSCognito();
  return (
    <div className='container-fluid pm-wrapper'>
      <div className='pm-logo-container'>
        <img src='/images/rca-logo.png' alt='SSM Assist Logo' />
      </div>

      <div className='intro-text'>
        <h3>Welcome</h3>
        <h5>Welcome to Refer Connect Assist</h5>
      </div>

      <div className='main-user-buttons'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='row'>
                <div className='col-md-4 col-sm-12'>
                  <div
                    className='user-button'
                    onClick={() => routeTo(ROUTES.WK_LOGIN)}
                  >
                    <div className='d-flex align-items-end'>
                      <div className='icon-left'>
                        <img src="/images/worker-icon1.png" alt=""/>
                      </div>
                      <div className='user-button-text'>
                        <span>I'm a</span> <br />
                       Worker
                      </div>
                    </div>
                    <div className='icon-right'>
                      <ArrowLongRightIcon />
                    </div>
                  </div>
                </div>

                <div className='col-md-4 col-sm-12'>
                  <div
                    className='user-button'
                    onClick={() => routeTo(ROUTES.OA_LOGIN)}
                  >
                    <div className='d-flex align-items-end'>
                      <div className='icon-left'>
                     <img src="/images/org-admin-icon1.png" alt=""/>
                      </div>
                      <div className='user-button-text'>
                        <span>I'm an</span> <br />
                        Organisation Admin
                      </div>
                    </div>
                    <div className='icon-right'>
                      <ArrowLongRightIcon className='h-6 w-6 text-blue-500' />
                    </div>
                  </div>
                </div>

                <div className='col-md-4 col-sm-12'>
                  <div
                    className='user-button'
                    onClick={() => routeTo(ROUTES.IA_LOGIN)}
                  >
                    <div className='d-flex align-items-end'>
                      <div className='icon-left'>
                      <img src="/images/incom-admin-icon.png" alt=""/>
                      </div>
                      <div className='user-button-text'>
                        <span>I'm an</span> <br />
                        inCommunity Admin
                      </div>
                    </div>
                    <div className='icon-right'>
                      <ArrowLongRightIcon className='h-6 w-6 text-blue-500' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
}

export default AppLanding;
