import React, { useState } from 'react';

import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';

import {
  ArrowRightIcon,
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import AppFooter from 'components/shared/footer';
import { apiGetIA } from 'core/api';
import {
  changePasswordImmediately,
  signIn,
  verifyUserCode,
} from 'core/plugins/Entry';
import AuthLayout from 'components/layouts/AuthLayout';
import { ROUTES } from 'core/routes';
import { VALIDATION_MESSAGE } from 'core/plugins/validator';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
function PMLanding() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [payload, setPayload] = React.useState({
    username: '',
    password: '',
    type: 'IA',
  });

  const onChange = e => {
    setPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [tempUser, setTempUser] = useState(null);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const handleVerificationCodeChange = e => {
    setVerificationCode(e.target.value);
  };

  const [isVerifing, setVerifing] = React.useState(false);
  const submitVerificationCode = async () => {
    setLoading(true);
    setVerifing(true);
    try {
      const verified = await verifyUserCode(tempUser, verificationCode);
      if (verified) {
        apiGetIA(tempUser.UUID).then(response => {
          if (response && response.message) {
            setVerifing(false);
            toast.success(`Welcome`);
            navigate(ROUTES.IA_DASHBOARD);
            setLoading(false);
          } else {
            setVerifing(false);
            toast.warn('User is not a valid inCommunity Admin');
          }
        });
        setShowVerificationModal(false);
      } else {
        setVerifing(false);
        toast.error('Invalid verification code');
      }
    } catch (error) {
      setVerifing(false);
      toast.error('Error verifying code');
    }
  };

  const login = e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      signIn(payload)
        .then(response => {
          if (response.user && response.user.username) {
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
              setShowChangePassword(true);

              setChangePayload({
                oldPassword: payload.password,
                newPassword: '',
                user: response,
                username: payload.username,
              });

              return;
            } else {
              if (response.requireVerification) {
                setTempUser(response.tempUser);
                setShowVerificationModal(true);
              }
            }
            //console.log(response)
          }
          //setLoading(false)
        })
        .finally(() => {
           setLoading(false)
        });
    }, 1000);
  };

  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [changePayload, setChangePayload] = React.useState({
    oldPassword: '',
    newPassword: '',
    user: '',
    confirmPassword: '',
    username: '',
  });
  const handleChangePasswordModalClose = () => {
    setShowChangePassword(false);
  };

  const formSubmitChangePassword = e => {
    e.preventDefault();

    if (
      !validator.isStrongPassword(changePayload.newPassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      toast.error(VALIDATION_MESSAGE.NOT_VALID_PASSWORD);
      return;
    }

    if (changePayload.newPassword === changePayload.confirmPassword) {
      changePasswordImmediately(changePayload).then(response => {
        if (response) {
          navigate(ROUTES.IA_DASHBOARD);
        }
      });

      return;
    }

    toast.error(VALIDATION_MESSAGE.PASSWORD_NOT_MATCH);
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const onChangePayload = e => {
    setChangePayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Modal
        show={showChangePassword}
        onHide={handleChangePasswordModalClose}
        backdrop='static'
        className='confirm-modal'
      >
        <Modal.Body>
          <div className='spacer-lg'></div>
          <div className='top-icon'>
            <EnvelopeIcon />
          </div>
          <div className='spacer-sm'></div>
          <h4>Change Password</h4>
          <p>
            Please change your temporary password <br />
          </p>

          <form onSubmit={formSubmitChangePassword}>
            <div className='input-element'>
              <div className='input-wrapper'>
                <input
                  style={{
                    paddingLeft: 0,
                  }}
                  autoFocus
                  name='newPassword'
                  type='password'
                  placeholder='New Password'
                  required
                  onChange={onChangePayload}
                ></input>
              </div>
            </div>

            <div className='input-element'>
              <div className='input-wrapper'>
                <input
                  style={{
                    paddingLeft: 0,
                  }}
                  autoFocus
                  name='confirmPassword'
                  type='password'
                  placeholder='Confirm Password'
                  required
                  onChange={onChangePayload}
                ></input>
              </div>
            </div>

            <div className='input-element text-center'>
              <button type='submit' className='primary-button-small'>
                <div className='button-text'>Change Password</div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showVerificationModal}
        onHide={() => setShowVerificationModal(false)}
        backdrop='static'
        className='verification-modal'
        centered
      >
        <Modal.Header>
          <Modal.Title>Verification Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter the verification code sent to your email.</p>
          <div className='input-element'>
            <div className='input-wrapper'>
              <input
                maxLength={6}
                type='text'
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                placeholder='Enter code here'
                className='form-control'
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='input-element'>
            <button
              onClick={submitVerificationCode}
              className='btn btn-primary primary-button'
              disabled={isVerifing}
            >
              {isVerifing ? <Spinner animation='border' size='sm' /> : 'Verify'}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className='container login-form-page '>
        <div className='d-flex flex-column justify-content-center'>
          <div className='row justify-content-center'>
            <div className='col-md-8 text-center'>
              <div className='spacer-lg'></div>
              <div className='spacer-lg'></div>
              <div className='main-icon'>
                <img src='/images/incom-admin-icon.png' alt='' />
              </div>
              <h2 className='text-center'>
                Login as an <br />
                inCommunity Admin
              </h2>
              <h4>Please sign in to continue</h4>
              <div className='spacer-lg'></div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-4'>
              <form onSubmit={login}>
                <div className='input-element'>
                  {/* <label>Username</label> */}
                  <div className='input-wrapper'>
                    <div className='left-icon'>
                      <UserIcon />
                    </div>
                    <input
                      autoFocus
                      name='username'
                      type='email'
                      placeholder='Email'
                      required
                      onChange={onChange}
                    ></input>
                  </div>
                </div>

                <div className='input-element'>
                  {/* <label>Password</label> */}
                  <div className='input-wrapper'>
                    <div className='left-icon'>
                      <LockClosedIcon />
                    </div>
                    <input
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password'
                      required
                      onChange={onChange}
                    ></input>
                    <div className='right-icon' onClick={togglePassword}>
                      {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                    </div>
                  </div>
                </div>

                <div className='input-element text-center'>
                  <button
                    disabled={loading}
                    type='submit'
                    className='primary-button'
                  >
                    <div className='button-text'>
                      {loading ? (
                        <Spinner animation='border' size='sm' />
                      ) : (
                        'Login'
                      )}
                    </div>
                    <div className='right-icon'>
                      <ArrowRightIcon />
                    </div>
                  </button>
                </div>
              </form>
              <div className='spacer-lg'></div>
              <div className='d-block justify-content-center text-center forgot-password-link-wrapper'>
                <a href={ROUTES.FORGOT}>Forgot Password ?</a>
              </div>
              <div className='spacer-lg'></div>
              <p
        className='text-center'
        style={{
          fontSize: '12px',
          color: '#888',
        }}
      >
        Refer Connet Assist by inCommunity
      </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PMLanding;
