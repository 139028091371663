const mobileRegex = /^(\+61|0)[2-478](\d{8}|\d{4} \d{4}|\d{3} \d{3} \d{3})$/
const countryCode = "+61"
export const isAustralianNumber = (value) => {
  return value.length > 0 && mobileRegex.test(value)
}

export const convertAustralianNumber = (number) => {
  if (isAustralianNumber(number)) {
    let digits = number.replace(/\D/g, "")
    if (digits.indexOf(0) === 0) {
      return countryCode + digits.substr(1)
    } else {
      return "+" + digits
    }
  }
}


export const VALIDATION_MESSAGE = {
  INVALID_PASSWORD:'Password invalid',
  NOT_VALID_PASSWORD:'Password must contain 8 characters with minimum 1 uppercase, 1 lowercase , 1 number and 1 symbol ',
  PASSWORD_NOT_MATCH:"Passwords doesn't match"

}