import React from 'react';

import { Badge, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Autocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ArrowLongLeftIcon } from '@heroicons/react/24/solid';

import { apiCreateSupportWorkers, apiGetAllIssues } from 'core/api';
import { googleMapAPIKEY, mapOptions } from 'core/plugins/config';
import { isAustralianNumber } from 'core/plugins/validator';
function AddSupportService() {
  const [value, setValue] = React.useState(false);

  const [step, setStep] = React.useState(0);

  const clearValue = () => {
    setValue(false);
    setStep(0);
  };

  const navigation = useNavigate();

  const routeTo = url => {
    navigation(url);
  };

  const [valueOk, setOk] = React.useState('ok');

  // const switchOk = (v) => {
  //   setOk(v)
  // }

  const [show, setModal] = React.useState(false);
  const handleClose = () => {
    setModal(false);
  };



  const [services, setServices] = React.useState(false);

  const [payload, setPayload] = React.useState({
    Mobile: '',
    Name: '',
    Email: '',
    Address: '',
    Location: '',
    STID: [],
    Genders:[],
    AgeMin:'',
    AgeMax:''
  });

  const onChange = e => {
    setPayload(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  let s_genders = [];

  const pushGender = e => {
    if (!s_genders.includes(e.target.value)) {
      s_genders.push(e.target.value);
    }

    if (!payload.Genders.includes(e.target.value)) {
      setPayload(prevState => ({
        ...prevState,
        Genders: [...payload.Genders, e.target.value],
      }));
    } else {
      setPayload(prevState => ({
        ...prevState,
        Genders: payload.Genders.filter(i => i !== e.target.value),
      }));
    }

  };
  let s_services = [];
  const pushServices = e => {
    if (!s_services.includes(e.target.value)) {
      s_services.push(e.target.value);
    }

    if (!payload.STID.includes(e.target.value)) {
      setPayload(prevState => ({
        ...prevState,
        STID: [...payload.STID, e.target.value],
      }));
    } else {
      setPayload(prevState => ({
        ...prevState,
        STID: payload.STID.filter(i => i !== e.target.value),
      }));
    }
  };

  const [progress, setProgress] = React.useState(false);
  const createService = e => {
    e.preventDefault();

    if (payload.Mobile.length > 0 && !isAustralianNumber(payload.Mobile)) {
      toast.error(
        'Please enter a valid Australian phone number eg +61234567890 / 0298765432',
      );
      return;
    }

    if(isNaN(payload.AgeMin)){
      toast.error(
        'Minimum age is should be a number',
      );
      return;
    }
    if(parseInt(payload.AgeMin) < 18 && parseInt(payload.AgeMin) > parseInt(payload.AgeMax)){
      toast.error(
        'Minimum age is 18 and should be less than maximum age',
      );
      return;
    }

    if(isNaN(payload.AgeMax)){
      toast.error(
        'Maximum age is should be a number',
      );
      return;
    }

    if(parseInt(payload.AgeMax) < parseInt(payload.AgeMin)){
      toast.error(
        'Maximum age is 99 and should be greater than mininum Age',
      );
      return;
    }
    if (payload.Genders && payload.Genders.length === 0) {
      toast.error('Please select supported genders');
      return;
    }
    if (payload.STID && payload.STID.length === 0) {
      toast.error('Please select a service');
      return;
    }

    setProgress(true);
    apiCreateSupportWorkers({
      Mobile: payload.Mobile,
    Name: payload.Name,
    Email: payload.Email,
    Location: payload.Location,
    STID: payload.STID,
    Gender:payload.Genders,
    AgeMin:payload.AgeMin,
    AgeMax:payload.AgeMax
    })
      .then(response => {
        toast.success('Support service created');
        routeTo('/IA/support-service');
        setProgress(false);
      })
      .finally(() => {
        setProgress(false);
      });
  };

  const [user_location, setUserLocation] = React.useState(false);

  const onPlaceSelected = place => {
    if (place && place?.formatted_address) {
      setUserLocation(place.formatted_address);

      setPayload(prevState => ({
        ...prevState,
        Location: place?.formatted_address,
      }));
    }
  };
  React.useEffect(() => {
    apiGetAllIssues().then(response => {
      if (response) {
        setServices(response);
      }
    });
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        className='modal-small'
      >
        <Modal.Body>
          <div className='spacer-lg'></div>
          <div className='top-icon'>
            <CheckCircleIcon />
          </div>
          <div className='spacer-sm'></div>
          <h4>Done</h4>
          <p>
            Support service <br />
            Added successfully
          </p>
          <div className='spacer-lg'></div>
          <div className='input-element text-center'>
            <button
              type='submit'
              className='primary-button-small'
              onClick={() => {
                handleClose();
                routeTo('/IA/support-service');
              }}
            >
              <div className='button-text'>Ok</div>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className='container inner-page '>
        <div className='spacer-lg'></div>
        <div className='d-flex flex-column'>
          <div className='row justify-content-center'>
          <div className="col-lg-7 col-md-7">
            <p className="admin-type">inCommunity Admin</p>
            </div>
            <div className='col-lg-7 col-md-7 position-relative'>
              <div className='d-flex align-items-center'>
                <div
                  className='left-round-button'
                  onClick={() => routeTo('/IA/support-service')}
                >
                  <ArrowLongLeftIcon />
                </div>
                <h2 className='text-left'>Add Support Service</h2>
              </div>

              {value && value !== 'no' && (
                <div className='ticket-item'>
                  <p>{value} assigned</p>
                  <div onClick={clearValue}>
                    <XMarkIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
          <form onSubmit={createService}>
            <div className='row justify-content-center'>
              <div className='col-lg-7 col-md-7'>
                <div className='spacer-lg'></div>

                <div className='input-element'>
                  <div className='input-normal'>
                    <label>Support service name</label>
                    <input
                      autoFocus
                      name='Name'
                      required
                      onChange={onChange}
                      type='text'
                      placeholder='Support service name'
                    ></input>
                  </div>
                </div>

                <div className='input-element'>
                  <div className='input-normal'>
                    <label>Phone number</label>
                    <input
                      name='Mobile'
                      required
                      onChange={onChange}
                      type='text'
                      placeholder='Phone number'
                    ></input>
                  </div>
                </div>

                <div className='input-element'>
                  <div className='input-normal'>
                    <label>Email</label>
                    <input
                      name='Email'
                      type='email'
                      required
                      onChange={onChange}
                      placeholder='Email'
                    ></input>
                  </div>
                </div>

                <div className='input-element'>
                  <div className='input-normal'>
                    <label>Location</label>

                    <Autocomplete
                      options={mapOptions}
                      apiKey={googleMapAPIKEY}
                      onPlaceSelected={place => onPlaceSelected(place)}
                    />
                  </div>
                </div>
                <div className='row'>
                <div className='col-md-6'>
                <div className='input-element'>
                  <div className='input-normal'>
                    <label>Min Age</label>
                    <input
                    maxLength={2}
                      name='AgeMin'
                      required
                      onChange={onChange}
                      type='text'
                      placeholder='Min Age'
                    ></input>
                  </div>
                </div>
                </div>
                <div className='col-md-6'>
                <div className='input-element'>
                  <div className='input-normal'>
                    <label>Max Age</label>
                    <input
                    maxLength={2}
                      name='AgeMax'
                      required
                      onChange={onChange}
                      type='text'
                      placeholder='Max age'
                    ></input>
                  </div>
                </div>
                </div>
               </div>
                <div className='input-element'>
                  <div className='input-normal'>
                    <label>Supported Gender</label>

                    <div className='gender-items'>
                      <div className='gender-item'>
                        <Badge bg='light'>
                          <input
                            id='male'
                            value="MALE"
                            type='checkbox'
                            onChange={pushGender}
                          ></input>
                          <label htmlFor='male'>Male</label>
                        </Badge>
                      </div>
                      <div className='gender-item'>
                        <Badge bg='light'>
                          <input
                          value="FEMALE"
                            id='female'
                            type='checkbox'
                            onChange={pushGender}
                          ></input>
                          <label htmlFor='female'>Female</label>
                        </Badge>
                      </div>
                      <div className='gender-item'>
                        <Badge bg='light'>
                          <input
                            id='other'
                            value="OTHER"
                            type='checkbox'
                            onChange={pushGender}
                          ></input>
                          <label htmlFor='other'>Other</label>
                        </Badge>
                      </div>
                    </div>
                    
                  </div>
                </div>

         

                <div className='input-element'>
                  <div className='input-normal '>
                    <label>Services offered</label>
                    <div>
                      {services &&
                        services.map(service => (
                          <div className='service-item pb-2' key={service.STID}>
                            <Badge bg='light'>
                              <input
                                onChange={pushServices}
                                type='checkbox'
                                id={service.STID}
                                value={service.STID}
                              />
                              <label htmlFor={service.STID}>{service.Title}</label>
                            </Badge>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='d-flex justify-content-center align-items-center'>
                  <div
                    className='input-element text-center'
                    style={{ marginRight: 24 }}
                  >
                    <button
                      type='submit'
                      className='primary-button no-animation'
                    >
                      <div className='button-text'>
                        {progress ? <Spinner size='sm' /> : 'Save Details'}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSupportService;
